import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigureService } from 'service/ng4-configure/ng4-configure.service';

const ChartColorScheme = ['#1ba3c6', '#2cb5c0', '#30bcad', '#21B087', '#33a65c', '#57a337', '#a2b627', '#d5bb21', '#f8b620', '#f89217', '#f06719', '#e03426', '#f64971', '#fc719e', '#eb73b3', '#ce69be', '#a26dc2', '#7873c0', '#4f7cba'];
const ChartHoverColorScheme = ['#1ba3c6', '#2cb5c0', '#30bcad', '#21B087', '#33a65c', '#57a337', '#a2b627', '#d5bb21', '#f8b620', '#f89217', '#f06719', '#e03426', '#f64971', '#fc719e', '#eb73b3', '#ce69be', '#a26dc2', '#7873c0', '#4f7cba']


@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  
  constructor(private http: HttpClient, private configService: ConfigureService) {
  }

  /**
   * Construct a dataset set object for Chart.js pie charts 
   * @param initialData Array of data to initialize the first dataset
   */
  getDefaultPiechartDatasets(initialData = []) {
    return [ { 
      data: initialData,
      backgroundColor: ChartColorScheme,
      hoverBackgroundColor: ChartHoverColorScheme,
      borderColor: 'white',
      hoverBorderColor: 'white',
      borderWidth: 1,
      hoverBorderWidth: 0
    }];
  }

  /**
   * Returns Chart.js chart options object for default doughnuts charts
   */
  getDefaultChartOptions(animated : boolean = true) {
    return {
      maintainAspectRatio: false,
      responsive: true,
      cutoutPercentage: 70,
      animation: { duration: animated ? 1000 : 0 },
      onHover: (event, activeElements) => {
        if (activeElements?.length > 0) {
          event.native.target.style.cursor = 'pointer';
        } else {
          event.native.target.style.cursor = 'auto';
        }
      }
    }
  }

  /**
   * Chart.js plugin that display two lines of data in the center of a chart
   * Data to be displayed is retrieved from properties 'centralDataLine1' and 'centralDataLine1' of chart options.
   */
  getCentralDataPlugin() {
    return [{
      id: 'textInCenter',
      afterDraw: (chart) => {
        if (chart.config.options.centralDataLine1) {
          const line1 = chart.config.options.centralDataLine1;
          const line2 = chart.config.options.centralDataLine2 ? chart.config.options.centralDataLine2 : '';
          const ctx = chart.ctx;
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.fillStyle = "#696e73";
          ctx.font = "bold 24px Arial";
          ctx.fillText(line1, centerX, centerY - 10);
          ctx.font = "bold 16px Arial";
          ctx.fillText(line2, centerX, centerY + 10);
          ctx.restore();
        }
      }
    }];
  }

  getStatisticsByModel(model: string){
    return this.http.get<Array<any>>(`${this.configService.config.apiUrl}/statistics/container/${model}/`);
  }

  getStatictisParent(model: string, containerId){
    return this.http.get<Array<any>>(`${this.configService.config.apiUrl}/statistics/container/${model}/${containerId}/parents/`);
  }

  getStatictisByGroup(model: string, containerId, group: string){
    return this.http.get<Array<any>>(`${this.configService.config.apiUrl}/statistics/container/${model}/${containerId}/group/`, {params: {group}});
  }

  getStatictisByGroupByLevel(model: string, containerId, group: string){
    return this.http.get<Array<any>>(`${this.configService.config.apiUrl}/statistics/container/${model}/${containerId}/group/level`, {params: {group}});
  }
  
  getScenarioSeries(id, path){
    return this.http.get<any>(`${this.configService.config.apiUrl}/scenario/${id}/series`, {params: {fields:[path]}});
  }
}
