import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { SecurePipe } from '../secure.pipe';


@Injectable({
  providedIn: 'root'
})
export class SVGToolkitService {

  constructor(private securePipe: SecurePipe) { }

  // get image real dimensions
  public imageDimensions(source) {
    return new Promise<any>((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img
        resolve({ width, height })
      }
      img.onerror = err => {
        reject(err);
      }
      img.src = source;
    })
  }

  // Transform file to base64
  public fileToBase64(file, res?, err?) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      res(reader.result);
    };
    reader.onerror = function (error) {
      err('Error: ', error);
    };
  }

  // Transform file URL to base64
  public urlToBase64(url, res, err) {
    this.securePipe.transform(url, true).subscribe(r => {
      var reader = new FileReader();
      reader.onloadend = function () {
        res(reader.result);
      }
      reader.onerror = function (error) {
        err('Error: ', error);
      };
      reader.readAsDataURL(<Blob>r);
    }, e => err(e));
  }

  // Create an image from the specified SVG element
  public createImage(svgElement : Element, width : number, height : number, callback: EventEmitter<any>) {
    var svgData = new XMLSerializer().serializeToString(svgElement);
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    var svgSize = svgElement.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;

    var img = document.createElement("img");
    img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));

    img.onload = () => {
        ctx.drawImage(img, 0, 0);
        this.getImagePortion(img, width, height, (blob) => {
        let file;
        let browser: any = window.navigator;
        if (!browser.msSaveBlob) { // detect if not Edge
            file = new File([blob], Date.now() + '.png');
        } else {
            const tempblob = new Blob([blob], {type: 'image/png'});
            file = this.blobToFile(tempblob, Date.now() + '.png');
        }
        callback.emit(file);
        })
    };
  }

  // crop image
  private getImagePortion(imgObj, newWidth, newHeight, callback) {
    //set up canvas for thumbnail
    var tnCanvas = document.createElement('canvas');
    var tnCanvasContext = tnCanvas.getContext('2d');
    tnCanvas.width = newWidth; tnCanvas.height = newHeight;

    /* use the sourceCanvas to duplicate the entire image. This step was crucial for iOS4 and under devices. Follow the link at the end of this post to see what happens when you don’t do this */
    var bufferCanvas = document.createElement('canvas');
    var bufferContext = bufferCanvas.getContext('2d');
    bufferCanvas.width = imgObj.width;
    bufferCanvas.height = imgObj.height;
    bufferContext.drawImage(imgObj, 0, 0);

    /* now we use the drawImage method to take the pixels from our bufferCanvas and draw them into our thumbnail canvas */
    tnCanvasContext.drawImage(bufferCanvas, (600 - newWidth) / 2, (400 - newHeight) / 2, newWidth, newHeight, 0, 0, newWidth, newHeight);
    return tnCanvas.toBlob(callback);
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }
}