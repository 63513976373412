import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private keycloakService: KeycloakService) { }

  transform(url: string, isfile?: boolean) {
    return new Observable(observer => {
      if (!isfile)
        observer.next("assets/images/loading-gif.gif")
      this.getToken().then(token => {
        let headers = new HttpHeaders({ Accept: 'application/*', Authorization: 'Bearer ' + token });
        this.http.get(url, { responseType: 'blob', headers })
          .pipe(map(val => isfile ? val : this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))))
          .toPromise()
          .then(res => observer.next(res))
          .catch(e => observer.error(e));
      });
    })
  }

  private async getToken() {
    return await this.keycloakService.getToken();
  }

}
