<div class="d-flex">
  <div [ngSwitch]="vViewType">
    <span *ngSwitchCase="'abstract'" class="value">
      {{ model['value']['abstracted'].toUpperCase() }}
    </span>
    <span *ngSwitchCase="'concrete'" class="value">
      <span *ngIf="customMLP && model['value']['concrete']  == 0;else concreteTemp">
        t0
      </span>
      <ng-template #concreteTemp>
        {{ ((reference > 0) && (model['value']['concrete'] > 0)) ? '+' : '' }}{{ model['value']['concrete'] }} {{
        (model['value']['unit'] || unitView[0]) }}
        <span *ngIf="customMLP"> from {{getReferenceLabel(reference)}}</span>
      </ng-template>
    </span>
    <span *ngSwitchCase="'range'" class="value">
      <ng-container [ngSwitch]="rangeViewType">
        <ng-container *ngSwitchCase="'>'">
          <span>{{ model['value']['minExclusive'] ? '>' : '≥'}}&nbsp;</span> <span>{{ ((reference > 0) &&
            (model['value']['min'] > 0)) ? '+' : '' }}{{ model['value']['min'] }} {{ model['value']['unit'] ||
            unitView[0] || '' }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'<'">
          <span>{{ model['value']['maxExclusive'] ? '<' : '≤' }}&nbsp;</span> <span>{{ ((reference > 0) &&
                (model['value']['max'] > 0)) ? '+' : '' }}{{ model['value']['max'] }} {{ model['value']['unit'] ||
                unitView[0] || '' }}</span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{model['value']['minExclusive'] ? ']' : '['}}</span>
          <span>{{ ((reference > 0) && (model['value']['min'] > 0)) ? '+' : '' }}{{ model['value']['min'] }}</span>
          <span>&nbsp;;&nbsp;</span>
          <span>{{ ((reference > 0) && (model['value']['max'] > 0)) ? '+' : '' }}{{ model['value']['max'] }}</span>
          <span>{{model['value']['maxExclusive'] ? '[' : ']'}}</span>
          <span>&nbsp;{{ model['value']['unit'] || unitView[0] }}</span>
        </ng-container>
      </ng-container>
      <span *ngIf="customMLP"> from {{getReferenceLabel(reference)}}</span>
    </span>
    <span *ngSwitchCase="'formula'" class="value ml-3">
      <div *ngFor="let item of model.value.variables">
        <span class="keyword">let</span> {{ item.name}} <span class="keyword">=</span> {{ item.jsonpath ?
        pathPipe(item.jsonpath) : '' }}
      </div>
      <span class="keyword">set value =</span> {{ model['value']['formula'] }}
    </span>
    <span *ngSwitchCase="'undef'" class="value">
      <small>
        <i>{{ undefinedLabel() }}</i>
      </small>
    </span>
    <span *ngSwitchCase="'invalid'" class="value">
      <small class="text-warning">
        <i>Invalid data</i>
      </small>
    </span>
  </div>
  <!-- Statistics button -->
  <div class="ml-auto stats-button">
    <mat-icon *ngIf="statistics && readonly" (click)="graph(path)" ngbTooltip="View statistics of {{ scheme.title }}"
      container="body">bar_chart</mat-icon>
  </div>
  <!-- Edition buttons -->
  <div *ngIf="!readonly">
    <app-multilevelparam-update #updateValueDialog [model]="model" [scheme]="scheme" [parentScheme]="parentScheme"
      [context]="context" [attributes]="attributes" [reference]="reference" (confirmed)="valueUpdated($event)"
      [formulaEnabled]="formulaEnabled">
    </app-multilevelparam-update>
    <button class="btn btn-sm btn-link" *ngIf="checkIfLastUpdated()" (click)="propagateValue()">
      <mat-icon aria-label="Propagate" data-toggle="tooltip" title="Propagate value to all following steps">
        arrow_forward</mat-icon>
    </button>
    <button class="btn btn-sm btn-link text-success btn-edit" [disabled]="pageUpdate" type="button"
      (click)="customMLP ? editEvent.emit() : updateValueDialog.show()">
      <mat-icon aria-label="Edit">edit</mat-icon>
    </button>
  </div>
</div>