import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { KeycloakService } from "keycloak-angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProfileDialogComponent } from "../profile-dialog/profile-dialog.component";
import { CommunService } from "../commun.service";
import { GroupService } from "../group.service";
import { HttpClient } from "@angular/common/http";
import { ConfigureService } from "service/ng4-configure/ng4-configure.service";
import { VersionInfos } from "model/collection";
import { forkJoin, of, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";
import pkg from "../../../../package.json";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public versions: any = [];
  public profile: any = {};

  groups = [];

  versionInfos = new VersionInfos();
  sub: Subscription = new Subscription();
  docBaseUrl = 'http://documentation-url/';
  section = 'scenario';

  constructor(
    private keycloakService: KeycloakService,
    private modalService: NgbModal,
    public service: CommunService,
    private groupService: GroupService,
    private http: HttpClient,
    public configService: ConfigureService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.keycloakService.isLoggedIn().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.loadIdentity();
        this.groupService
          .getAdministrablesGroups()
          .subscribe((groups) => (this.groups = groups));
      }
    });

    this.docBaseUrl = this.configService.config.documentationUrl;

    this.sub.add(
      forkJoin([
        this.configService.getVersions().pipe(
          map((resp) => resp),
          catchError((err) => of([]))
        ),
        this.http.get<any>("/assets/config.json").pipe(
          map((resp) => resp.apiUrl),
          catchError((err) => of(""))
        ),
        this.http.get<any>("/assets/keycloak.json").pipe(
          map((resp) => resp["auth-server-url"]),
          catchError((err) => of(""))
        ),
      ]).subscribe(([versions, apiUrl, authUrl]) => {
        this.versionInfos.dashboardVersion = pkg.version;
        this.versionInfos.statisticsVersion =
          pkg.dependencies["@mosar/mosar-statistics-dashboard"];

        const apiVersion =
          versions.find(
            (version) => version.name === "mosar-scenariomanager-interface"
          )?.version || "Not found";
        const backendVersion =
          versions.find(
            (version) => version.name === "mosar-scenariomanager-impl"
          )?.version || "Not found";
        this.versionInfos.datamodelVersion = apiVersion;
        this.versionInfos.apiVersion = apiVersion;
        this.versionInfos.backendVersion = backendVersion;
        this.versionInfos.apiUrl = apiUrl;
        this.versionInfos.authUrl = authUrl;
      })
    );

    this.router.events.subscribe((val) => {
      if (this.location.path().includes('testprotocol')) {
        this.section = 'testprotocol';
      }
      else if (this.location.path().includes('usecase')) {
        this.section = 'usecase';
      }
      else {
        this.section = 'scenario';
      }
    });
  }

  public logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.keycloakService.logout();
  }

  public openProfileDialog() {
    const modalRef = this.modalService.open(ProfileDialogComponent);
    modalRef.componentInstance.profile = this.profile;

    modalRef.result.then(
      (result) => {
        // If OK code here
      },
      (reason) => {
        // If modal dismissed code here
      }
    );
  }

  private loadIdentity() {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();

    this.keycloakService.loadUserProfile().then((profile) => {
      this.profile = profile;
      if (keycloakInstance && keycloakInstance.tokenParsed)
        this.profile.groups = keycloakInstance.tokenParsed["groups"];
    });
  }
}
