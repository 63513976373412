import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigureService } from '../../service/ng4-configure/ng4-configure.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class CommunService {

    private warning: string;

    constructor(
        public http: HttpClient,
        public configService: ConfigureService
    ) { }

    setWarning(warning: string){
        this.warning = warning;
    }

    getWarning(){
        return this.warning;
    }

}
