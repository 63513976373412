import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import {
  DataModelService,
  ParamScheme,
} from "@mosar/mosar-dashboard-datamodel";
import { MosarStatsService } from "@mosar/mosar-statistics-dashboard";

enum VariableViewType {
  ABSTRACT = "abstract",
  CONCRETE = "concrete",
  RANGE = "range",
  FORMULA = "formula",
  UNDEF = "undef",
  INVALID = "invalid",
}

enum RangeView {
  RANGE = "range",
  SUP = ">",
  INF = "<",
}

@Component({
  selector: "app-multilevelparam-view",
  templateUrl: "./multilevelparam-view.component.html",
  styleUrls: ["../parameter-view/parameter-view.component.scss"], //NB: CSS retrieved from parent to avoid values style duplication
})
export class MultiLevelParamViewComponent implements OnInit, OnChanges {
  @Input("readonly") readonly: boolean;
  @Input("scheme") scheme: ParamScheme;
  @Input("parentScheme") parentScheme: ParamScheme = null; // Optional
  @Input("context") context: any;
  @Input("model") model: any; // Data value
  @Input("annotation") annotation: any;
  @Input("stepIndex") stepIndex: number;
  @Input("changed") changed: boolean = true; // False if data is the same as its previous value
  @Input("lastUpdatedAttr") lastUpdatedAttr: any;
  @Input("attributes") attributes: any;
  @Input("reference") reference: any;
  @Input("formulaEnabled") formulaEnabled: boolean;
  @Input("path") path: string;
  @Input("level") level: any;
  @Input("references") references: Array<any>;
  @Input("customMLP") customMLP: boolean;
  @Output("editEvent") editEvent = new EventEmitter<any>();
  @Input("statistics") statistics: boolean = false; // If true, display the stats access

  @Output() submittedEvent = new EventEmitter<any>();
  @Output() propagatedEvent = new EventEmitter<string>();
  @Output() update = new EventEmitter<any>();

  public currentAttr: any = {};
  public vViewType: VariableViewType = VariableViewType.UNDEF;
  public unitView = [];
  public rangeViewType = RangeView.RANGE;
  public variablesScheme;

  constructor(
    private dataModel: DataModelService,
    private statsService: MosarStatsService
  ) {
    this.variablesScheme = dataModel.getClassProperty("VariableMapping");
  }

  ngOnInit() {
    this.currentAttr.stepIndex = this.stepIndex;
    if (this.path && this.level) {
      let path = this.path.split(".");
      this.currentAttr.attr = path
        .slice(path.lastIndexOf(this.scheme.name) - this.level)
        .join(".");
    } else {
      this.currentAttr.attr = this.scheme.name;
    }
  }

  getReferenceLabel(ref) {
    return this.references.find((r) => r.id == ref)?.name;
  }

  pathPipe(path: string) {
    return path
      .replace(/steps\[\?\(@.id==([0-9]+)*\)\]/, (item, $1) => {
        const step = this.context && this.context.steps
          ? this.context.steps.find((step) => step.id == $1)
          : null;
        return step ? `steps[${step.name}]` : item;
      })
      .replace(/actorsState\[([0-9]+)*\]/, (item, $1) => {
        const actor = this.context && this.context.actors ? this.context.actors[$1] : null;
        return actor ? `actorsState[${actor.name}]` : item;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reference && !changes.reference.isFirstChange() &&
      this.model && this.model.value && this.model.value.abstracted) {
      this.valueUpdated({ value: { ...this.model.value, abstracted: this.model.value.abstracted, description: this.model.description ?? 'No description' } });
    }
    this.updateView();
  }

  public checkIfLastUpdated() {
    return (
      JSON.stringify(this.lastUpdatedAttr) === JSON.stringify(this.currentAttr)
    );
  }

  public propagateValue() {
    this.propagatedEvent.emit(this.currentAttr.attr);
  }

  public valueUpdated($event) {
    this.submittedEvent.emit({
      stepIndex: this.stepIndex,
      attr: this.scheme.name,
    });
    this.model = $event;
    this.update.emit(this.model);
    this.updateView();
  }

  graph(path) {
    this.statsService.open(
      null,
      this.context.id,
      this.dataModel.scenarioModel,
      path.replace(/steps\[(.*?)\]/, `steps[${this.stepIndex}]`)
    );
  }

  private fieldIsSet(fieldName, obj): boolean {
    if (fieldName in obj) {
      let value = obj[fieldName];
      if (value === 0) {
        return true;
      }
      if (value == null || value === "") {
        return false;
      }
      return true;
    }
    return false;
  }

  private updateView() {
    // Compute view type
    let view: VariableViewType = VariableViewType.UNDEF;
    let range: RangeView = RangeView.RANGE;

    if (this.model) {
      if (!("value" in this.model)) {
        view = VariableViewType.UNDEF;
      } else if (this.model["value"]) {
        let value = this.model["value"];
        if (this.fieldIsSet("concrete", value)) {
          view = VariableViewType.CONCRETE;
        } else if (this.fieldIsSet("abstracted", value)) {
          view = VariableViewType.ABSTRACT;
        } else if (this.fieldIsSet("formula", value) && !this.fieldIsSet("min", value) && !this.fieldIsSet("max", value)) {
          view = VariableViewType.FORMULA;
        } else if (
          (this.fieldIsSet("min", value) ||
            this.fieldIsSet("max", value)) &&
          this.fieldIsSet("formula", value)
        ) {
          view = VariableViewType.RANGE;
          if (!this.fieldIsSet("min", value)) {
            range = RangeView.INF;
          }
          if (!this.fieldIsSet("max", value)) {
            range = RangeView.SUP;
          }
        }
        else if (
          (this.fieldIsSet("min", value) ||
            this.fieldIsSet("max", value))
        ) {
          view = VariableViewType.RANGE;
          if (!this.fieldIsSet("min", value)) {
            range = RangeView.INF;
          }
          if (!this.fieldIsSet("max", value)) {
            range = RangeView.SUP;
          }
        }
      } else {
        view = VariableViewType.INVALID;
      }
    }

    this.vViewType = view;
    this.rangeViewType = range;

    // Retrieve unit label
    if (this.attributes) {
      this.unitView = this.attributes.unitLabel;
      if (typeof this.unitView == "string") {
        this.unitView = [this.unitView];
      }
    } else if (this.scheme) {
      this.unitView = this.dataModel.getMultiLevelParamAttributes(
        this.scheme.className
      ).unitLabel;
    }
  }

  undefinedLabel() {
    if (this.annotation) {
      if (this.annotation.isUnknown) return "Unknown";
      if (this.annotation.mandatory) return "To be defined";
    }
    return "Not defined";
  }
}
