import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThumbnailgeneratorComponent } from './thumbnailgenerator/thumbnailgenerator.component';
import { NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ThumbnailService } from './thumbnail.service';
import { MaterialModule } from 'layout/material.module';
import { FormsModule } from '@angular/forms';
import { SecurePipe } from './secure.pipe';
import { ImportExportService } from './import-export.service';

@NgModule({
  entryComponents: [
    ThumbnailgeneratorComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbDropdownModule,
    MaterialModule,
    FormsModule
  ],
  declarations: [ThumbnailgeneratorComponent,
  SecurePipe],
  providers: [ThumbnailService,ImportExportService,SecurePipe]
})
export class ThumbGeneratorModule { }
