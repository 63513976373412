import { NgModule } from "@angular/core";
// included angular used material components
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";

const materialModules = [
  MatIconModule,
  MatDialogModule,
  MatSnackBarModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
];

@NgModule({
  imports: materialModules,
  exports: materialModules,
})
export class MaterialModule {}
