import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CollectionService } from '../../collection/collection.service';
import { SecurePipe } from '../secure.pipe';

// fileable => that can hold a file
export interface Filable {
  filename : string,
  file : string
}


@Component({
  selector: 'app-attachment-view',
  templateUrl: './attachment-view.component.html',
  styleUrls: ['./attachment-view.component.scss']
})
export class AttachmentViewComponent implements OnInit {
  @Input('readonly') readonly = false;
  @Input('filable') filable: Filable;
  @Input('showFilename') showFilename= false;

  public storeUrl : string;

  constructor(private restService : CollectionService, private secure : SecurePipe) {
  }
  
  public ngOnInit() {
    this.storeUrl = this.restService.getFileStoreUrl();
  }

  public updateFile(file) {
    this.filable.file = file.id;
    this.filable.filename = file.name;
  }

  public extractName(fileId : string) {
    if (!fileId || fileId.length == 0)
      return '';
    if (this.filable.filename) {
      let cleanFilename = this.filable.filename.replace(/\//g, "_");
      return encodeURIComponent(cleanFilename);
    }
    return fileId.substring(fileId.indexOf('_') + 1);
  }

  downloadImg(file){
    let url = this.storeUrl+'/'+file+'?name='+this.extractName(file)
    this.secure.transform(url, file).subscribe((res: any) => {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.setAttribute("download", this.extractName(file));
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
}
