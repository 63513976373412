import { /*HttpClientModule,*/ HttpClient } from '@angular/common/http';
import { ConfigureOptions } from './configure-options';
import { ConfigureService } from './ng4-configure.service';
import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataModelService } from '@mosar/mosar-dashboard-datamodel';

@NgModule({
  imports: [
    CommonModule
    //HttpClientModule
  ],
  declarations: [],
  providers: [
    ConfigureService,
    ConfigureOptions,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [ConfigureService, HttpClient, DataModelService]
    }
  ]
})
export class NgConfigureModule {
  public static forRoot(): ModuleWithProviders<NgConfigureModule> {
    return {
        ngModule: NgConfigureModule,
        providers: [ConfigureService, ConfigureOptions]
    };
  }
}

export function init(configService: ConfigureService, http: HttpClient) {
  return () => configService.load();
}
