import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CollectionService } from '../../collection/collection.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-file-update-dialog',
  templateUrl: './file-update-dialog.component.html',
  styleUrls: ['./file-update-dialog.component.scss']
})
export class FileUpdateDialogComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild("dialogTemplate", { static: true }) private dialogModal: TemplateRef<any>;

  @Input('file-id') fileId: String;
  @Output() update = new EventEmitter<any>();

  form: FormGroup;
  loading: boolean = false;
  fileLabel = 'Choose file';
  modal: NgbModalRef;

  constructor(
        private modalService: NgbModal,
        private restService: CollectionService,
        private fb: FormBuilder,
        public snackBar: MatSnackBar) {
    // Create form
    this.form = this.fb.group({
      attachment: [null, Validators.required]
    });
  }

  show(content) {
    this.modal = this.modalService.open(this.dialogModal);
  }

  onFileChange(event) {
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.form.get('attachment').setValue(file);
      this.fileLabel = file.name;
    }
  }

  removeFile() {
    this.modal.close();
    this.update.emit("");
    this.snackBar.open('File removed', 'Ok', { duration: 1500 });
  }

  onSubmit() {
    this.loading = true;

    // Prepare data to send
    let data = new FormData();
    data.append('file', this.form.get('attachment').value);

    this.restService.storeFile(data)
      .then((response: any) => {
        this.loading = false;
        this.fileId = response.newid;
        this.update.emit({id: response.newid, name: this.form.get('attachment').value.name});
        this.modal.close();
        this.snackBar.open('File uploaded', 'Ok', { duration: 1500 });
      });
  }

}
