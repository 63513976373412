import { ThumbnailItemView } from "../thumbnail-item-view";
import { ThumbnailCurve } from '../thumbnail-curve/thumbnail-curve-model'
import { TweenLite } from "gsap/TweenMax";
import Draggable from "gsap/Draggable";


export class ThumbnailCurveView extends ThumbnailItemView {

    protected item : ThumbnailCurve;

    protected static readonly FROM_CONTROL_POINT_CONTROL = 'from-control-point';
    protected static readonly TO_CONTROL_POINT_CONTROL = 'to-control-point';
    protected static readonly CURVE_END_POSITION_CONTROL = 'curve-end-position';
    protected static readonly COLOR_CONTROL = 'color';


    constructor(item : ThumbnailCurve, parent : Element, id : number) {
        super(item, parent, id);
        this.setControlEnabled(ThumbnailItemView.DRAG_CONTROL, true);
        this.setControlEnabled(ThumbnailItemView.SELECTION_CONTROL, true);
        this.setControlEnabled(ThumbnailCurveView.FROM_CONTROL_POINT_CONTROL, true);
        this.setControlEnabled(ThumbnailCurveView.TO_CONTROL_POINT_CONTROL, true);
        this.setControlEnabled(ThumbnailCurveView.CURVE_END_POSITION_CONTROL, true);
        this.setControlEnabled(ThumbnailCurveView.COLOR_CONTROL, true);
    }

    protected createControl(control : string) {
        super.createControl(control);
        let elementName = 'thumb-element-' + control + '-' + this.id;
        if (control == ThumbnailCurveView.FROM_CONTROL_POINT_CONTROL) {
            this.createFromControlPointControl(elementName);
        }
        else if (control == ThumbnailCurveView.TO_CONTROL_POINT_CONTROL) {
            this.createToControlPointControl(elementName);
        }
        else if (control == ThumbnailCurveView.CURVE_END_POSITION_CONTROL) {
            this.createCurveEndPositionControl(elementName);
        }
    }

    public createItemView() : Element {
        // View creation
        const element = super.createItemView('path');
        element.setAttributeNS(null, 'fill', "transparent");
        element.setAttributeNS(null, 'stroke-width', '5');

        // View update
        let updateCurve = () => {
            let def =
                'M0,0 ' +
                'C' + this.item.getFromControlPoint().x + ',' + this.item.getFromControlPoint().y + ' ' +
                this.item.getToControlPoint().x + ',' + this.item.getToControlPoint().y + ' ' +
                (this.item.getEndX()-this.item.getOriginX()) + ' ' + (this.item.getEndY()-this.item.getOriginY());
            element.setAttributeNS(null, 'd', def);
            element.setAttributeNS(null, 'stroke', this.item.getColor());
        }
        updateCurve();

        // Events registering
        this.item.onItemChange.subscribe(item => {
            updateCurve();
        });

        return element;
    }

    /**
     * Add a handle to control the position of the "from control point" to the specified element.
     */
    private createFromControlPointControl(elementName : string) {
        // View creation
        const elres = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        elres.setAttributeNS(null, 'id', elementName);
        elres.setAttributeNS(null, 'cx', '0');
        elres.setAttributeNS(null, 'cy', '0');
        elres.setAttributeNS(null, 'r', '5');
        elres.setAttributeNS(null, 'fill', 'yellow');
        this.parent.appendChild(elres);

        // Positioning
        const updatePosition = () => {
            TweenLite.set('#' + elementName, {
                rotation: 0
            });
            TweenLite.set('#' + elementName, {
                x: this.item.getFromControlPoint().x + this.item.getOriginX(),
                y: this.item.getFromControlPoint().y + this.item.getOriginY(),
                transformOrigin: (this.item.getWidth()) / 2 + ' ' + (this.item.getHeight()) / 2,
                rotation: this.item.getRotation()
            });
        };
        updatePosition();

        // Control creation
        const fcp = Draggable.create('#' + elementName, {
            type: 'x,y',
            cursor: 'nwse-resize',
            bounds: this.parent,
            onDrag: (e) => {
                let x = fcp[0].x - this.item.getOriginX();
                let y = fcp[0].y - this.item.getOriginY();
                this.item.setFromControlPoint(x, y);
            }
        });

        // Update position on item changes
        this.item.onItemChange.subscribe(item => {
            updatePosition();
        });
    }

    /**
     * Add a handle to control the "to control point" to the specified element.
     */
    private createToControlPointControl(elementName : string) {
        // View creation
        const elres = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        elres.setAttributeNS(null, 'id', elementName);
        elres.setAttributeNS(null, 'cx', '0');
        elres.setAttributeNS(null, 'cy', '0');
        elres.setAttributeNS(null, 'r', '5');
        elres.setAttributeNS(null, 'fill', 'green');
        this.parent.appendChild(elres);

        // Positioning
        const updatePosition = () => {
            TweenLite.set('#' + elementName, {
                rotation: 0
            });
            TweenLite.set('#' + elementName, {
                x: this.item.getToControlPoint().x + this.item.getOriginX(),
                y: this.item.getToControlPoint().y + this.item.getOriginY(),
                transformOrigin: (this.item.getWidth()) / 2 + ' ' + (this.item.getHeight()) / 2,
                rotation: this.item.getRotation()
            });
        };
        updatePosition();

        // Control creation
        const tcp = Draggable.create('#' + elementName, {
            type: 'x,y',
            cursor: 'nwse-resize',
            bounds: this.parent,
            onDrag: (e) => {
                let x = tcp[0].x - this.item.getOriginX();
                let y = tcp[0].y -this.item.getOriginY();
                this.item.setToControlPoint(x, y);
            }
        });

        // Update position on item changes
        this.item.onItemChange.subscribe(item => {
            updatePosition();
        });
    }

    /**
     * Add a handle for end position control, at the top left of the specified element.
     */
    private createCurveEndPositionControl(elementName : string) {
        // View creation
        const elrot = document.createElementNS("http://www.w3.org/2000/svg", 'rect');
        elrot.setAttributeNS(null, 'id', elementName);
        elrot.setAttributeNS(null, 'height', '10');
        elrot.setAttributeNS(null, 'width', '10');
        elrot.setAttributeNS(null, 'fill', 'green');
        this.parent.appendChild(elrot);

        // Positioning
        const updatePosition = () => {
            TweenLite.set('#' + elementName, {
                x: this.item.getEndX() - 5,
                y: this.item.getEndY() - 5,
                transformOrigin: (this.item.getWidth() + 10) / 2 + ' ' + (this.item.getHeight() + 10) / 2,
                rotation: this.item.getRotation()
            });
        };
        updatePosition();

        // Control creation
        const elementPosition = Draggable.create('#' + elementName, {
            type: 'x,y',
            onDrag: (e) => {
                let dx = elementPosition[0].x - this.item.getEndX();
                let dy = elementPosition[0].y - this.item.getEndY();
                this.item.setEndPosition(elementPosition[0].x, elementPosition[0].y);
                this.item.setToControlPoint(this.item.getToControlPoint().x + dx, this.item.getToControlPoint().y + dy);
            }
        });

        // Update position on item changes
        this.item.onItemChange.subscribe(item => {
            updatePosition();
        });
    }

}