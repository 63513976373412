<!-- New code version, displaying the value AND the key  -->
<ng-container *ngIf="key">
  <div
    [ngClass]="{ 'level-0': parent.level+1 == 0, 'level-1': parent.level+1 == 1, 'level-2': parent.level+1 == 2, 'level-3': parent.level+1 == 3 }">

    <!-- Key -->
    <div class="key help">
      {{ titleOverride || scheme.title || scheme.name }}
      <div [ngClass]="{'opacity': showInfo(annotation)}"
        ngbTooltip="{{ readonly ? 'View' : 'Edit' }} parameter annotations">
        <app-annotation [context]="context" [readonly]='readonly' [path]="path+scheme.name" [annotations]="annotations"
          [simulation]="context.simulation" (annotationChange)="annotationChange($event)"></app-annotation>
      </div>
      <app-contextual-help-icon [propertyScheme]="scheme" [objectScheme]="parent.scheme"></app-contextual-help-icon>
    </div>

    <div class="row m-0">
      <div class="col-1 p-0 d-flex align-items-center" *ngIf="hasAnnotation(annotation)" style="max-width: 35px;">
        <span class="badge badge-warning text-light" *ngIf="annotation?.isUnknown">?</span>
        <span class="badge badge-success" *ngIf="annotation?.mandatory">M</span>
        <span class="badge badge-secondary" *ngIf="annotation?.optional">Opt</span>
      </div>
      <div class="p-0" [ngClass]="hasAnnotation(annotation) ? 'col-11' : 'col-12'"
        [style.maxWidth]="hasAnnotation(annotation) ? 'calc(100% - 35px)' : none">

        <!-- Value view -->
        <span *ngIf="readonly" [ngClass]="{ 'text-primary': highlightValue(scheme) == true }">
          <span class="value multiline">
            <span *ngIf="!referenceError && !notDefined">{{ getLabel() }}</span>
            <span *ngIf="referenceError" class="text-danger small"><i>Reference broken</i></span>
            <span *ngIf="notDefined" class="small"><i>{{ undefinedLabel(annotation) }}</i></span>
          </span>
        </span>

        <!-- Value edition (Path) -->
        <ng-select appendTo="body" *ngIf="!readonly && scheme.className == 'Path'" (click)="updateReferences()" [items]="references"
          bindLabel="label" bindValue="key" [(ngModel)]="key_model" class="select-path"
          (change)="updatePathValue($event)">
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <div class="text-center">
              <span class="ng-option-label" style="white-space:normal">{{item.label1 || '--'}}</span><br>
              <mat-icon>keyboard_arrow_down</mat-icon><br>
              <span class="ng-option-label">{{item.label2 || '--'}}</span>
            </div>
          </ng-template>
        </ng-select>

        <!-- Value edition (Default) -->
        <ng-select appendTo="body" *ngIf="!readonly && scheme.className != 'Path'" (click)="updateReferences()" [(ngModel)]="model"
          (change)="updateValue($event)">
          <ng-option *ngFor="let ref of references" [value]="toNumber(ref.id)">
            {{ref.label}}
          </ng-option>
        </ng-select>

      </div>
    </div>

  </div>
</ng-container>

<!-- Previous version, displaying only the value -->
<ng-container *ngIf="!key">
  <ng-container *ngIf="readonly">
    <span *ngIf="!referenceError && !notDefined">{{ getLabel() }}</span>
    <span *ngIf="referenceError" class="text-danger small"><i>Reference broken</i></span>
    <span *ngIf="notDefined" class="small"><i>{{ undefinedLabel(annotation) }}</i></span>
  </ng-container>
  <ng-select appendTo="body" *ngIf="!readonly" (click)="updateReferences()" [(ngModel)]="model" (change)="updateValue($event)">
    <ng-option *ngFor="let ref of references" [value]="toNumber(ref.id)">
      {{ref.label}}
    </ng-option>
  </ng-select>
</ng-container>