import { Injectable } from '@angular/core';

import { IFilter } from '../../model/filter';

import { LocalStorageService } from './local-storage.service';
import { IFilterParameter } from 'model/filterParameter';
import { IFilterOperator } from 'model/filterOperator';

@Injectable()
export class FiltersService {
  constructor(
    private localStorageService: LocalStorageService
  ) { }

  public updateOrCreateFilterWithPrimitive(filters: IFilter[], key: string, operator: string, value: string | number, isHidden: boolean = false) {
    let exist = false;

    if (value === null || value === undefined)
      return this.removeFilter(filters, key);

    // Search for filter corresponding to key and update its value
    filters.map((filter) => {
      if (filter.parameter.name === key) {
        filter.value = value;
        filter.hidden = isHidden;
        exist = true;
      }
    });

    // If key doesn't exist in filters, we push it
    if (!exist) {
      filters.push({
        parameter: { name: key },
        operator: { operator: operator },
        value: value,
        hidden: isHidden
      });
    }

    this.localStorageService.setItem('filters', filters);
  }

  public createFilterWithObject(filters: IFilter[], filter: IFilter) {
    filters.push(filter);
    this.localStorageService.setItem('filters', filters);
  }
  
  public createFiltersWithObjects(filters: IFilter[], newfilters: IFilter[]) {
    filters.push(...newfilters);
    this.localStorageService.setItem('filters', filters);
    return filters;
  }

  public forceUpdateFilter(filters: IFilter[], key: number, newFilter: IFilter) {
    filters[key] = newFilter;
    this.localStorageService.setItem('filters', filters);
  }

  public forceUpdateFilters(filters: IFilter[], indexes : number[], newFilters: IFilter[]) {
    filters = filters.filter((f,i) => indexes.indexOf(i) < 0);
    filters.push(...newFilters)
    this.localStorageService.setItem('filters', filters);
    return filters;
  }

  public removeFilter(filters: IFilter[], key: string) {
    const index = filters.findIndex(filter => filter.parameter.name === key);
    this.removeFilterByIndex(filters, index);
  }

  public removeFilterByIndex(filters: IFilter[], index : number) {
    if (index >= 0) {
        filters.splice(index, 1);
    }
    this.localStorageService.setItem('filters', filters);
  }

  public removeFiltersByIndexes(filters: IFilter[], indexes : number[]) {
    filters = filters.filter((f,i) => indexes.indexOf(i) < 0);
    this.localStorageService.setItem('filters', filters);
    return filters;
  }

  // Called each time container changes
  public initScenariosFilters() {
    const filters = this.localStorageService.getItem('filters') || [];

    return filters;
  }

}
