<div *ngIf="!isSingleLevel">

    <div class="btn-group" role="group" *ngIf="!readonly">
        <button class="btn btn-success btn-sm" disabled="true">
            <mat-icon>edit</mat-icon>
        </button>
        <div ngbDropdown class="dropdown btn-group" role="group">
            <button class="btn btn-success btn-sm" id="getElementsDropdown" ngbDropdownToggle>
                <mat-icon>add</mat-icon> {{scheme.title}}
            </button>
            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="getElementsDropdown">
                <app-custom-enum-item *ngFor="let item of itemsList" [element]="item" (selected)="addElement($event)"
                    [usedelements]="model">
                </app-custom-enum-item>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column" [ngClass]="{'space-gap': readonly}">
        <ng-container *ngFor="let id of model; let i = index">
            <p class="m-0" style="margin-left: 2px;">
                <ng-container *ngIf="isFromFilter == undefined">
                    <ng-container *ngIf="path == 'classificationCode' else otherPath">
                        {{ (itemsList | wrapFn:getDescriptionClassificationCode:id) }}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isFromFilter != undefined">
                    <ng-container *ngIf="path?.includes('classificationCode') else otherPath">
                        {{ (itemsList | wrapFn:getDescriptionClassificationCode:id) }}
                    </ng-container>
                </ng-container>

                <ng-template #otherPath>
                    {{ (itemsList | wrapFn:getItem:id)?.description|| 'Info: No description' }}
                </ng-template>

                <mat-icon *ngIf="!readonly && !single" class="float-right pointer text-danger"
                    (click)="removeElement(i)">delete</mat-icon>
            </p>
        </ng-container>
    </div>

</div>

<div *ngIf="isSingleLevel">

    <ng-container *ngIf="!readonly">
        <ng-select [items]="itemsList" bindValue="id" bindLabel="description" [multiple]="true"
            [maxSelectedItems]="scheme.maxItems" [(ngModel)]="model" [placeholder]="scheme.description"
            (change)="update.emit(model)">
        </ng-select>
    </ng-container>

    <ng-container *ngIf="readonly">
        <div class="comma-separator" *ngIf="model"><span *ngFor="let id of model">{{ (itemsList |
                wrapFn:getItem:id)?.description|| 'Info: No description' }}</span></div>
        <div *ngIf="!model || (model && !model.length)">
            <small><i> Not Defined </i></small>
        </div>
    </ng-container>

</div>