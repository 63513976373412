import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DataModelService } from "@mosar/mosar-dashboard-datamodel";
import { pathWithName } from "../utils-shared";

@Component({
  selector: "app-jsonpath",
  templateUrl: "./jsonpath.component.html",
  styleUrls: ["./jsonpath.component.scss"],
})
export class JsonpathComponent implements OnInit {
  @Input("context") context: any;
  @Input("model") model: any;
  @Input("placeholder") placeholder: any;
  @Input() disabledPaths: string[] = [];
  @Output("select") select = new EventEmitter<any>();

  paths: Array<any>;

  constructor(private dataModel: DataModelService) {}

  ngOnInit() {
    this.paths = this.generatePaths(this.context).map((path) => {
      return {
        path,
        label: pathWithName(this.context, path),
        fullPath: path,
        disabled: false,
      };
    });
    if (this.disabledPaths.length) {
      this.paths = this.paths.map((itemPath) => {
        if (this.disabledPaths.includes(itemPath.fullPath)) {
          return { ...itemPath, disabled: true };
        }
        return itemPath;
      });
    }
  }

  selected(model) {
    this.select.emit(model);
  }

  // get possible paths
  private generatePaths(root) {
    let paths = [];
    let nodes = [
      {
        obj: root,
        scheme: null,
        subSchemes: this.dataModel.getParameters("Scenario"),
        path: "$",
      },
    ];
    while (nodes.length > 0) {
      let node = nodes.pop();
      Object.keys(node.obj || {}).forEach((k) => {
        let path;
        let scheme;
        if (Array.isArray(node.obj)) {
          if (typeof node.obj[k] === "object" && "id" in node.obj[k]) {
            path = `${node.path}[?(@.id==${node.obj[k].id})]`;
          } else {
            path = `${node.path}[${k}]`;
          }
          scheme = node.scheme;
        } else {
          path = `${node.path}.${k}`;
          scheme = node.subSchemes.find((s) => s.name == k);
        }
        let subSchemes = this.dataModel.getParameters(
          scheme ? scheme.className : null
        );
        if (typeof node.obj[k] === "object") {
          nodes.unshift({
            obj: node.obj[k],
            scheme,
            subSchemes,
            path: path,
          });
        }
        if (subSchemes.find((s) => s.className == "MultiLevelParam"))
          paths.push(path);
      });
    }
    return paths;
  }
}
