import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigureService } from '../../service/ng4-configure/ng4-configure.service';
import { DataModelService } from '@mosar/mosar-dashboard-datamodel';
import { IPagination } from '../../model/pagination';
import { IFilter } from '../../model/filter';
import { flatMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {
  constructor(
        public http: HttpClient, 
        public configService: ConfigureService,
        private dataModel : DataModelService) {
  }

  private handleError(method: String) {
    return (err: any) => {
        if (err.constructor.name === "String")
          if (err.includes("The concrete scenarios of this scenario are already being computed. Please wait."))
            return err;
        return throwError(err);
    }
  }

  createScenario(name, parentId, container, scenarioType?) {
    return this.dataModel.getModelTemplate('scenario').pipe(flatMap((scenarioTemplate: any) => {
      let scenario = {...scenarioTemplate, name, type:scenarioType,  metadata: {dataModelVersion:this.dataModel.getDataModelVersion()}}

      let createScenario = this.http.post(`${this.configService.config.apiUrl}/scenario`, {
        scenario: scenario,
        comment: ""
      },
      { params: {container} });

      if (parentId) {

        scenario.parent = parentId;
        return this.dataModel.getModelTemplate('step').pipe(flatMap((stepTemplate: any) => {
          if(!stepTemplate.name)
            stepTemplate.name = `Step 1`;
          scenario.steps = [stepTemplate];
          scenario.actors = [];
          return createScenario;
        }))

      } else 
        return createScenario;

    }))

  }

  duplicateScenario(id, container, newParent, copyParents, copyChildren, move, snapshot) {
    let params = { container, newParent, copyParents, copyChildren, move, snapshot };
    return this.http.post(`${this.configService.config.apiUrl}/scenario/${id}/duplicate`, null, { params });
  }

  getScenarioById(id) {
    return this.http.get(`${this.configService.config.apiUrl}/scenario/${id}`);
  }

  findScenarios(pagination: IPagination = null, container: string, filters: IFilter[] = null) {
    const parameters: any = {
      // page: pagination ? pagination.page : null,
      // rowPerPage: pagination ? pagination.rowPerPage : null,
      filters: filters,
      container: container
    };

    return this.http.get<any>(`${this.configService.config.apiUrl}/scenario`, {
      params: parameters
    });
  }

  findScenariosTree(pagination: IPagination = null, container: string, filters: IFilter[] = null, scenarioFamily?) {
    const parameters: any = {
      page: pagination ? pagination.page : null,
      rowPerPage: pagination ? pagination.rowPerPage : null,
      filters: filters,
      container: container
    };
    if(scenarioFamily)
      parameters.scenarioFamily = scenarioFamily;

    return this.http.get<any>(`${this.configService.config.apiUrl}/scenario/tree`, {
      params: parameters
    });
  }

  getScenarioHistory(id) {
    return this.http.get<any>(`${this.configService.config.apiUrl}/scenario/${id}/history`);
  }

  deleteScenarios(id, deleteChildren?, deleteChildrenOnly?) {
    let params = deleteChildren ? {deleteChildren, deleteChildrenOnly} : {};
    return this.http.delete(`${this.configService.config.apiUrl}/scenario/${id}`, {params});
  }

  updateScenarios(scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: ""
    };
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${scenario.id}`, scenarioPostBody);
  }

  setupScenario(scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: ""
    };
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${scenario.id}/setup`, scenarioPostBody);
  }

  /* Scenario status method */

  updateScenariosAsync(id, scenario) {
    const scenarioPostBody = {
      scenario: scenario,
      comment: ""
    };
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${id}`, scenarioPostBody);
  }

  updateScenarioStatusWorkflow(scenario, comment) {
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${scenario.id}/status/workflow`, comment);
  }

  invalidateScenarioStatus(scenario, comment) {
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${scenario.id}/status/invalidate`, comment);
  }

  reopenScenario(scenario, comment) {
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${scenario.id}/status/reset`, comment);
  }

  feedbackScenario(scenario, comment) {
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${scenario.id}/status/feedback`, comment);
  }

  getScenarioAnnotations(id) {
    return this.http.get<any>(`${this.configService.config.apiUrl}/scenario/${id}/annotations`);
  }

  setScenarioAnnotations(id, annotations: Array<any>) {
    return this.http.put<any>(`${this.configService.config.apiUrl}/scenario/${id}/annotation`, annotations);
  }

  synchronizeScenario(id) {
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${id}/synchronize`, null).pipe(
      catchError(this.handleError('synchronizeScenario'))
    );
  }

  exportScenario(id) {
    return this.http.get(`${this.configService.config.apiUrl}/export/scenarios`, 
      {
        /* FIXME: Uncomment this code -> it seems to override the header set by the keycloak interceptor
        headers : {
          Accept: 'application/*',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        },*/
        responseType: 'blob',
        params: {scenariosToExport: id}
      }
    )
  }

  updateSnapshot(id) {
    return this.http.put(`${this.configService.config.apiUrl}/scenario/${id}/update-snapshot`, null).pipe(
      catchError(this.handleError('updateSnapshot'))
    )
  }
}
