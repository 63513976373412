import { Injectable } from '@angular/core';
import { Observable ,  throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams, HttpResponse } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';

import { IFilter } from 'model/filter';
import { ConfigureService } from 'service/ng4-configure/ng4-configure.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunService } from 'app/shared/commun.service';
import { FiltersContainer } from 'model/collection';

declare var require: any;

var config = require('assets/config.json');

@Injectable({
  providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    public configService: ConfigureService,
    public keycloakService: KeycloakService,
    public snackBar: MatSnackBar,
    protected router: Router,
    public communservice: CommunService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let skipError = false;
    if (request.params){
      skipError = request.params.has('skipError');
      request = this.handleRequestParams(request);
    }

    // Concat endpoint with API base url
    // const newRequest = request.clone({
    //   url: request.url,
    //   headers: _headers
    // });

    return next.handle(request).pipe( map(event => {
      // if (event instanceof HttpResponse) {
      //   this.communservice.setWarning(event.headers.get('x-multiple-sessions'))
      // }
      return event;
    }), catchError(err => {
      if(!skipError){
        console.error(err);
        let messageOnly;
        // Make sure we handle our defined status code
        if (err.error && err.error.message) {
          // Don't know if it works
          console.error('errGot', err)
          if (err.status == 401) {
            this.keycloakService.login();
          } else if ([400, 404].indexOf(err.status) >= 0) {
            messageOnly = err.error.message.substring(err.error.message.indexOf(": ") + 2, err.error.message.length);
          } else if (err.status === 403) {
            messageOnly = "";
          } else if (err.status === 500) {
            messageOnly = "Internal Server Error";
          } else {
            messageOnly = err.error.message.substring(err.error.message.indexOf("Exception: "), err.error.message.length);
          }
        } else if (err.error && err.message) {
          messageOnly = "The server could not be reached";
        }
        console.error('messageOnly', messageOnly);
        if(messageOnly != "") {
          this.snackBar.open(messageOnly + "\n", 'Ok', { duration: 5000 });
        }
      }
      return throwError(err.error.message || err.statusText);
    }));
  }

  private handleRequestParams(request: HttpRequest<any>) {
    const parameters: any = request.params;
    const parametersKeys = parameters.keys();
    let httpParams = new HttpParams();

    for (let i = 0; i < parametersKeys.length; i++) {
      const key = parametersKeys[i];

      if (key === 'filters') {
        httpParams = this.fillHttpParamsWithFilters(httpParams, parameters);
      } else {
        const parameter = parameters.getAll(key);

        for (let j = 0; j < parameter.length; j++) {
          httpParams = httpParams.append(key, parameter[j]);
        }
      }
    }
    httpParams = httpParams.delete('skipError');
    request = request.clone({
      params: httpParams,
      withCredentials: request.url.includes(config.apiUrl)
    });
    return request;
  }

  private fillHttpParamsWithFilters(httpParams: any, parameters: any) {
    const filters = parameters.getAll('filters');

    if(!filters.length) return httpParams;
    // Workaround
    if (filters.length === 1 && filters[0] && filters[0].parameter && filters[0].operator)
      httpParams = httpParams.append('filters', "");
    
    for (let j = 0; j < filters.length; j++) {
      const filter = filters[j];

      if (filter && filter.parameter && filter.operator && filter.value !== null && filter.value !== undefined && filter.value !== "") {
        let parameterName = filter.parameter.name;
        httpParams = httpParams.append('filters', `${parameterName}:${filter.operator.operator}:${filter.value}`);
      }
    }

    if(('field' in filters[0])) {
      filters.forEach(filter => {
        httpParams = httpParams.append('filters', `${filter.field}:${filter.operator}:${filter.value}`);
      });
    }
    return httpParams;
  }
}