import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppStatusService {

  isEditionOngoing: boolean;
  editionStatusChange: Subject<boolean> = new Subject<boolean>();

  constructor()  {
    this.editionStatusChange.subscribe((value) => {
        this.isEditionOngoing = value
    });
  }

  public setEditionOngoing(editionOngoing : boolean) {
    this.editionStatusChange.next(editionOngoing);
  }
}
