<ng-template #dialogTemplate let-c="close" let-d="dismiss">
  <div class="modal-header bg-dark text-light">
    <span class="modal-title">
      <mat-icon>edit</mat-icon>
      {{ scheme.title ? scheme.title : scheme.name }}
    </span>
    <button type="button" class="close" aria-label="Close" (click)="d('close')">
      <span aria-hidden="true" class="text-light">&times;</span>
    </button>
  </div>
  <div class="modal-body row">
    <div class="col-md-12" *ngIf="!formula">
      <div class="input-section form-row">
        <div class="col-9">
          <p class="text-secondary multiline">
            {{ description }}
            &nbsp;<small *ngIf="docLink"><i><a href="{{ docLink }}" target="_blank">View more...</a></i></small>
          </p>
        </div>
        <div class="col-3" [align]="'right'">
          <ng-container *ngIf="unitLabels && unitLabels.length > 0">
            <label for="unit">Unit</label>
            <div class="btn-group btn-group-toggle" id="unit" ngbRadioGroup name="radioUnit"
              [(ngModel)]="updatedValue?.value.unit">
              <label *ngFor="let item of unitLabels" ngbButtonLabel class="btn-sm"
                [ngClass]="(unitLabels.length > 1) ? 'btn-outline-primary' : 'btn-secondary'">
                <input ngbButton type="radio" [value]="item"> {{item}}
              </label>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="abstractValues && abstractValues.length > 0" class="input-section"
        [ngClass]="{'selected': abstractSelected}">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="abstractCheck" [(ngModel)]="abstractSelected"
            (click)="setSelectedType('abstract')">
          <label class="form-check-label" for="abstractCheck">
            Abstract value
            <mat-icon class="help" *ngIf="enumsDescription" [ngbTooltip]="enumsDescription" container="body"
              tooltipClass="multiline">help</mat-icon>
          </label>
        </div>
        <ng-select [(ngModel)]="updatedValue.value.abstracted" [clearable]="false" appendTo="body"
          (change)="setSelectedType('abstract')">
          <ng-option *ngFor="let item of abstractValues" value="{{item}}">
            {{ item.toUpperCase() }}
          </ng-option>
        </ng-select>
      </div>
      <ng-container *ngIf="mlpAttributes.unitLabel">
        <div class="input-section" [ngClass]="{'selected': rangeSelected}">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="rangeCheck" [(ngModel)]="rangeSelected"
              (click)="setSelectedType('range')">

            <div class="d-flex justify-content-between">
              <label class="form-check-label" for="rangeCheck">Range</label>
              <label *ngIf="isErrorRange" class="form-check-label error-range" for="rangeCheck">/!\ Invalide values (min >
                max)</label>
            </div>

          </div>
          <div class="form-row">
            <div class="col-1 text-center align-bottom">
              &nbsp;
            </div>
            <div class="input-group col-5">
              <div class="input-group-prepend" data-toggle="tooltip"
                title="Excluded: If not checked, the value is included in the range (default), if checked, the value is excluded from the range.">
                <div class="input-group-text">
                  <input type="checkbox" [(ngModel)]="updatedValue.value.minExclusive">
                  <span>Excl.</span>
                </div>
              </div>
              <input type="number" class="form-control text-right" step="any" [min]="scheme.minimum"
                (input)="checkInputvaluesFromRange($event)" [(ngModel)]="updatedValue.value.min"
                (focus)="setSelectedType('range')" (change)="setSelectedType('range')">
              <div class="input-group-append">
                <div class="input-group-text">{{ updatedValue?.value?.unit || unitLabels[0] }}</div>
              </div>
            </div>
            <div class="col-1 text-center">
              to
            </div>
            <div class="input-group col-5">
              <div class="input-group-prepend" data-toggle="tooltip"
                title="Excluded: If not checked, the value is included in the range (default), if checked, the value is excluded from the range.">
                <div class="input-group-text">
                  <input type="checkbox" [(ngModel)]="updatedValue.value.maxExclusive">
                  <span>Excl.</span>
                </div>
              </div>
              <input type="number" class="form-control text-right" step="any"
                [min]="updatedValue.value.min || scheme.minimum" (input)="checkInputvaluesFromRange($event)"
                [(ngModel)]="updatedValue.value.max" (focus)="setSelectedType('range')"
                (change)="setSelectedType('range')">
              <div class="input-group-append">
                <div class="input-group-text">{{ updatedValue?.value?.unit || unitLabels[0] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-section" [ngClass]="{'selected': concreteSelected}">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="concreteCheck" [(ngModel)]="concreteSelected"
              (click)="setSelectedType('concrete')">
            <label class="form-check-label" for="concreteCheck">Value</label>
          </div>
          <div class="input-group mb-2">
            <input type="number" class="form-control text-right" [min]="scheme.minimum"
              [(ngModel)]="updatedValue.value.concrete" (focus)="setSelectedType('concrete')"
              (change)="setSelectedType('concrete')">
            <div class="input-group-append">
              <div class="input-group-text">{{ updatedValue?.value?.unit || unitLabels[0] }}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <button class="btn btn-primary btn-sm float-right" *ngIf="formulaEnabled" (click)="formula=true">Formula<mat-icon>
          keyboard_arrow_right</mat-icon></button>
    </div>
    <div class="col-md-12" *ngIf="formula">
      <div class="input-section" [ngClass]="{'selected': formulaSelected}">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="formulaCheck" [(ngModel)]="formulaSelected"
            (click)="setSelectedType('formula')">
          <label class="form-check-label" for="formulaCheck">Formula</label>
        </div>
        <div>
          <label class="d-inline">Variables: </label>
          <button class="btn btn-success btn-sm p-0" (click)="addVariable(updatedValue.value.variables)">
            <mat-icon>add</mat-icon>
          </button>
          <ng-container *ngFor="let item of updatedValue.value.variables">
            <app-parameter-view [scheme]="variablesScheme" [model]="item" [readonly]="false" [context]="context">
            </app-parameter-view>
            <hr class="w-100">
          </ng-container>
        </div>
        <div class="input-group mb-2">
          <div class="input-group-append">
            <div class="input-group-text">=</div>
          </div>
          <textarea class="form-control" [(ngModel)]="updatedValue.value.formula" (focus)="setSelectedType('formula')"
            (change)="setSelectedType('formula')">
        </textarea>
        </div>
      </div>
      <button class="btn btn-primary btn-sm float-left" *ngIf="formulaEnabled || formula" (click)="formula=false">
        <mat-icon>keyboard_arrow_left</mat-icon>Values
      </button>
    </div>
  </div>
  <div class="modal-footer bg-light d-flex justify-content-between">
    <button type="button" class="btn" (click)="d('undef')">
      <mat-icon class="text-dark" aria-label="Delete">delete</mat-icon>
    </button>
    <div>
      <button type="button" class="btn mr-2" (click)="d('cancel')">
        Cancel
      </button>
      <button type="button" class="btn btn-success ml-2" (click)="c('delete')" [disabled]="isErrorRange">
        Ok
      </button>
    </div>
  </div>
</ng-template>