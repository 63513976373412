import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThumbnailgeneratorComponent } from './thumbnailgenerator/thumbnailgenerator.component';
import { Thumbnail } from "./thumbnailgenerator/thumbnail-model";
import { ImportExportService } from './import-export.service';

@Injectable({
  providedIn: 'root'
})
export class ThumbnailService {

  data;

  constructor(private modalService: NgbModal, private importexportService: ImportExportService) {
  }

  init(elements?: Array<any>, background?: any, id?: number, thumbnail?: Thumbnail, errorPicturable?: boolean) {
    return new Promise<any>((resolve, reject) => {
      let modalRef = this.modalService.open(ThumbnailgeneratorComponent, { centered: true, size: 'xl', backdrop: 'static' });
      modalRef.componentInstance.model = thumbnail;
      modalRef.componentInstance.elements = elements || [];
      modalRef.componentInstance.background = background;
      modalRef.componentInstance.errorPicturable = errorPicturable;
      modalRef.componentInstance.onSubmit.subscribe(img => {
        resolve({ img, modalRef, data: this.importexportService.exportThumbnail(modalRef.componentInstance.thumbnail) });
      })
    })
  }

  open(elements?: Array<any>, background?: any, id?: number, data?: any, errorPicturable?: boolean) {
    return new Promise<any>((resolve, reject) => {
      if (data)
        this.importexportService.importThumbnail(data).then(thumbnail => {
          this.init(elements, background, id, thumbnail, errorPicturable).then(res => resolve(res))
        })
      else
        this.init(elements, background, id, new Thumbnail(), errorPicturable).then(res => resolve(res))
    })
  }

  

}
