import {
  Component,
  Input,
  Output,
  PipeTransform,
  EventEmitter,
} from "@angular/core";
import {
  DataModelService,
  ParamScheme,
} from "@mosar/mosar-dashboard-datamodel";
import { PropertiesFilter } from "../../../pipes/properties-filter.pipe";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MosarStatsService } from "@mosar/mosar-statistics-dashboard";

enum ViewType {
  STRING = "string",
  NUMBER = "number",
  TEXT = "text",
  ENUM = "enum",
  BOOLEAN = "boolean",
  OBJECT = "object",
  MULTILEVEL = "multilevel",
  FILE = "file",
  REF = "ref",
  ARRAY = "array",
  ENUMS = "enums",
  CUSTOM_ENUM = "custom-enum",
}

@Component({
  selector: "app-parameter-view",
  templateUrl: "./parameter-view.component.html",
  styleUrls: ["./parameter-view.component.scss"],
})
export class ParameterViewComponent {
  @Input("path") path: string; // path of the param
  @Input("stepId") stepId: any;
  @Input("actorId") actorId: string;
  @Input("annotations") annotations: any;
  @Input("context") context: any = null; // Scenario / infrastructure or actor, usefull in case of reference
  @Input("readonly") readonly: boolean = false;
  @Input("scheme") scheme: ParamScheme; // Data scheme
  @Input("model") model: any; // Data value
  @Input("prevModel") prevModel: any = null; // Previous data value, used if param in array to highlight changes
  @Input("nextModel") nextModel: any = null; // Next data value, used if param in array to highlight changes
  @Input("level") level = 0; // Indentation level
  @Input("parentModel") parentModel = null; // Data of the container object if one, very specific use for some references
  @Input("filter") propertiesFilter: PipeTransform = new PropertiesFilter();
  @Input("inline") inlined: boolean = false;
  @Input("isStepsContext") isStepsContext: boolean; //FIXME: Unused property
  @Input("lastUpdatedAttr") lastUpdatedAttr: any;
  @Input("stepIndex") stepIndex: number;
  @Input("attributes") attributes: any;
  @Input("formulaEnabled") formulaEnabled: boolean;
  @Input("statistics") statistics: boolean;
  @Input() simulation: boolean;

  @Output() propagatedEvent = new EventEmitter<any>();
  @Output() lastUpdatedEvent = new EventEmitter<any>();

  private annotation = {};

  constructor(
    private dataModel: DataModelService,
    private ngbModal: NgbModal
  ) {}

  public propagateValue(attr) {
    this.propagatedEvent.emit(attr);
  }

  public setLastUpdatedAttr(lastUpdatedAttr, name) {
    if (lastUpdatedAttr && name)
      lastUpdatedAttr.attr = name + "." + lastUpdatedAttr.attr;
    this.lastUpdatedEvent.emit(lastUpdatedAttr);
  }

  public getSubProperties(className: string): ParamScheme[] {
    let properties = [];

    for (let property of this.dataModel.getParameters(className)) {
      property["viewType"] = this.getViewType(property);
      properties.push(property);
    }
    return this.propertiesFilter.transform(properties);
  }

  private getViewType(property: ParamScheme) {
    // Primitive types
    if (property.className == "primitive") {
      if (property["enum"]) {
        return ViewType.ENUM;
      }
      if (property["minLength"]) {
        return ViewType.TEXT;
      }
      if (property["x-reference"]) {
        return ViewType.REF;
      }
      if (property["format"] == "file") {
        return ViewType.FILE;
      }
      switch (property.type) {
        case "boolean":
          return ViewType.BOOLEAN;
        case "number":
        case "integer":
          return ViewType.NUMBER;
        default:
          return ViewType.STRING;
      }
    }
    // ENUM
    else if (property.className == "enums") {
      return ViewType.ENUMS;
    }
    // Custom enum
    else if (property.className == "custom-enum") {
      return ViewType.CUSTOM_ENUM;
    }
    // Objects
    else if (property.className == "") {
      console.log("Empty class name");
      return "";
    } else {
      if (this.dataModel.isMultiLevelParam(property)) {
        return ViewType.MULTILEVEL;
      }
      if (property.type == "array") {
        return ViewType.ARRAY;
      }
      return ViewType.OBJECT;
    }
  }

  updateValue(subPropName, value) {
    this.model[subPropName] = value;
  }

  highlightValue(scheme: ParamScheme) {
    let prevResult = ParameterViewComponent.areModelsEqual(
      scheme,
      this.model,
      this.prevModel
    );
    if (prevResult == false) return true;
    return false;
  }

  public static areModelsEqual(scheme: ParamScheme, model1, model2) {
    if (model1 && model2) {
      if (scheme["viewType"] == "multilevel") {
        let val1 = model1[scheme.name] ? model1[scheme.name].value : null;
        let val2 = model2[scheme.name] ? model2[scheme.name].value : null;
        if (model1.reference == model2.reference)
          // Compare references on parent level if exists
          return this.equalsMultiLevelParams(val1, val2);
        else return false;
      }
      if (scheme["viewType"] == "object") {
        return true; // Diff will be computed on next level
      } else {
        return model1[scheme.name] == model2[scheme.name];
      }
    }
    return null;
  }

  private static equalsMultiLevelParams(val1, val2): boolean {
    if (val1 == null) return val2 == null;
    if (val2 == null) return val1 == null;
    return (
      val1["concrete"] == val2["concrete"] &&
      val1["abstracted"] == val2["abstracted"] &&
      val1["min"] == val2["min"] &&
      val1["max"] == val2["max"] &&
      val1["formula"] == val2["formula"]
    );
  }

  setEditable(name) {
    typeof this.model[name] == "boolean"
      ? (this.model[name] = null)
      : (this.model[name] = false);
  }

  isBoolean(value) {
    return typeof value == "boolean";
  }

  toNumber(value) {
    if (isNaN(value)) return value;
    else return Number(value);
  }

  pushToArray(array, subProp) {
    array.push(this.dataModel.createFullObject(subProp.className));
  }

  removeFromArray(i, array) {
    array.splice(i, 1);
  }

  annotationChange(annotation, propName) {
    setTimeout(() => {
      this.annotation[propName] = annotation;
    });
  }

  showInfo(annotation) {
    return annotation
      ? annotation.mandatory ||
          annotation.optional ||
          annotation.input ||
          annotation.trigger ||
          annotation.expected
      : false;
  }

  firstLine(string: string) {
    return string.split("\n")[0];
  }

  undefinedLabel(annotation) {
    if (annotation) {
      if (annotation.isUnknown) return "Unknown";
      if (annotation.mandatory) return "To be defined";
    }
    return "Not defined";
  }

  hasAnnotation(annotation) {
    return annotation
      ? annotation.mandatory || annotation.optional || annotation.isUnknown
      : false;
  }
}
