import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  constructor() { }

  public setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  public clearCache(key: string = null) {
    if (key)
      localStorage.removeItem(key);
    else
      localStorage.clear();
  }
}
