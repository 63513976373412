import { ThumbnailItemView } from '../thumbnail-item-view'
import { ThumbnailLine } from './thumbnail-line-model';
import { TweenLite } from "gsap/TweenMax";


export class ThumbnailLineView extends ThumbnailItemView {

    protected item: ThumbnailLine;
    protected static readonly COLOR_CONTROL = 'color';

    constructor(item: ThumbnailLine, parent: Element, id: number) {
        super(item, parent, id);
        this.setControlEnabled(ThumbnailItemView.DRAG_CONTROL, true);
        this.setControlEnabled(ThumbnailItemView.SELECTION_CONTROL, true);
        this.setControlEnabled(ThumbnailItemView.RESIZE_CONTROL, true);
        this.setControlEnabled(ThumbnailItemView.ROTATE_CONTROL, true);
        this.setControlEnabled(ThumbnailLineView.COLOR_CONTROL, true);
    }

    protected createControl(control: string) {
        super.createControl(control);
    }

    public createItemView(): Element {

        const element = super.createItemView('line');

        // Line view
        const updateLine = () => {
            /**
             * We only define x2,y2 for the line bc of TweenLite (x,y) :
             * TweenLite (x,y) is the translation from the (0,0), it is implemented bc of super.createItemView method
             * The translation is defined as (x,y) = (item.originX, item.originY)
             * So if (x1,y1) was defined it would be translated at (x1 + item.originX ,y1 + item.originY) [ if not defined x1 = 0, y1 = 0]
             * It is the same pb for (x2,y2) => we translate them back
             */
            element.setAttributeNS(null, 'x2', (this.item.getEndX() - this.item.getOriginX()).toString());
            element.setAttributeNS(null, 'y2', (this.item.getEndY() - this.item.getOriginY()).toString());
            element.setAttributeNS(null, 'stroke-width', "5");
            element.setAttributeNS(null, 'stroke', this.item.getColor());

            // Dashed line view
            if (this.item.getType() == 'dline') {
                element.setAttributeNS(null, 'stroke-dasharray', "4,1");
            }


            // Arrow line view : using marker with a polygon as an arrow head
            else if (this.item.getType() == 'arrowLine') {

                // In each creation remove marker unused
                for (let i = 0; i < this.id; i++) {
                    let testmark = document.getElementById("arrowhead-" + i);
                    let testLine = document.getElementById("thumb-element-" + i);
                    if (testmark != undefined && testLine == undefined) {
                        this.parent.removeChild(testmark);
                    }
                }

                // remove marker linked to the element to update it
                let testmark = document.getElementById("arrowhead-" + this.id);
                if (testmark != undefined) {
                    this.parent.removeChild(testmark);
                }

                // Creation of the first/ updated marker
                let mark = document.createElementNS("http://www.w3.org/2000/svg", "marker");
                mark.setAttribute("id", "arrowhead-" + this.id);
                mark.setAttribute("markerWidth", "4");
                mark.setAttribute("markerHeight", "4");
                mark.setAttribute("refX", "0");
                mark.setAttribute("refY", "2");
                mark.setAttribute("orient", "auto");
                mark.setAttribute("fill", this.item.getColor());

                let pol = document.createElementNS("http://www.w3.org/2000/svg", "polygon")
                pol.setAttribute("points", "0 0, 4 2, 0 4");

                mark.appendChild(pol)
                this.parent.prepend(mark);

                element.setAttributeNS(null, 'marker-end', "url(#arrowhead-" + this.id + ")");
            }
        }
        updateLine();
        this.item.onItemChange.subscribe(item => {
            updateLine();
        });

        return element;
    }
}