<div class="modal-header bg-dark text-light">
  <h5 class="modal-title">MOSAR Illustration Editor</h5>
  <span aria-hidden="true" class="text-light closeBtn" (click)="closeWindow('Do you want to quit without saving ? \n All changes will be lost.')">&times;
  </span>
</div>



<div class="d-flex">

  <!-- Left side of the body => Tools, SVG vue, Vue modifications-->
  <div class="mx-5">
    <!-- Tools buttons-->
    <div class="d-flex m-3">
      <button class="btn btn-light" data-toggle="tooltip" data-placement="top" title="Delete" (click)="deleteItem()">
        <mat-icon class="text-primary">delete</mat-icon>
      </button>

      <div ngbDropdown class="d-flex">
        <button class="btn btn-light" id="dropdownLine" data-toggle="tooltip" data-placement="top" title="Add line element" ngbDropdownToggle>
          <mat-icon class="text-primary">gesture</mat-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownLine" class="dropdown-menu">
          <button class="dropdown-item form-control form-control-sm" (click)="createLine('line')">Line</button>
          <button class="dropdown-item form-control form-control-sm" (click)="createLine('dline')">Dotted line</button>
          <button class="dropdown-item form-control form-control-sm" (click)="createLine('arrowLine')">Arrow</button>
          <button class="dropdown-item form-control form-control-sm" (click)="createCurve()">Path</button>
        </div>
      </div>

      <button class="btn btn-light" data-toggle="tooltip" data-placement="top" title="Add annotation" (click)="createAnnotate()">
        <mat-icon class="text-primary">text_fields</mat-icon>
      </button>

      <button class="btn btn-light" id="dropdownColor" style="visibility:hidden;" data-toggle="tooltip" data-placement="top" title="Change color" (click)="colorPalette.click()">
        <input type="color" #colorPalette name="colorPalette" hidden=true [(ngModel)]="color"
          (ngModelChange)="colorChange(color)">
        <mat-icon class="text-primary">palette</mat-icon>
      </button>

      <button class="btn btn-light ml-auto" data-toggle="tooltip" data-placement="top" title="Restore previous state" (click)="restorePrevState()">
        <mat-icon class="text-primary">undo</mat-icon>
      </button>

    </div>

    <!-- SVG Vue-->
    <div class="m-3 border" style="max-height: 400px;max-width: 600px;" >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        id="thumb-container" width="600" height="400" viewBox="0 0 600 400" (wheel)="wheelZoom($event)">
      </svg>
    </div>

    <!-- SVG Vue modificator-->
    <div class="d-flex align-items-center justify-content-around m-3">
      <label>Ratio :</label>
      <select [(ngModel)]="svgView.selectedRatio" (ngModelChange)="createCropper()">
        <option *ngFor="let ratio of ratios" [value]="ratio.value"> {{ratio.label}}</option>
      </select>
      <label>Size :</label><input type="range" min=0.10 max=1 step=0.01 style="width: 100px;"
        [(ngModel)]="svgView.sizeToCrop" (ngModelChange)="createCropper()" />


      <div class="btn-group m-2" role="group">
        <button class="btn btn-light" data-toggle="tooltip" data-placement="top" title="Zoom in" (click)="zoom(2)">
          <mat-icon class="text-primary">zoom_in</mat-icon>
        </button>
        <button class="btn btn-light" data-toggle="tooltip" data-placement="top" title="Reset zoom" (click)="zoom(0)">
          Reset
        </button>
        <button class="btn btn-light" data-toggle="tooltip" data-placement="top" title="Zoom out" (click)="zoom(0.5)">
          <mat-icon class="text-primary">zoom_out</mat-icon>
        </button>
      </div>

      <div class="btn-group  my-1" role="group">
        <div class="btn-group-vertical">
          <button class="btn btn-sm" style="visibility: hidden;">
          </button>
          <button class="btn btn-light  btn-sm" data-toggle="tooltip" data-placement="top" title="View left" (click)="moveView('left')">
            <mat-icon class="text-primary">keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="btn-group-vertical">
          <button class="btn btn-light  btn-sm" data-toggle="tooltip" data-placement="top" title="View up" (click)="moveView('up')">
            <mat-icon class="text-primary">keyboard_arrow_up</mat-icon>
          </button>

          <button class="btn btn-light btn-sm" data-toggle="tooltip" data-placement="top" title="View down" (click)="moveView('down')">
            <mat-icon class="text-primary">keyboard_arrow_down</mat-icon>
          </button>
        </div>
        <div class="btn-group-vertical">
          <button class="btn btn-sm" style="visibility: hidden;">
          </button>
          <button class="btn btn-light  btn-sm" data-toggle="tooltip" data-placement="top" title="View right" (click)="moveView('right')">
            <mat-icon class="text-primary">keyboard_arrow_right</mat-icon>
          </button>
        </div>

      </div>
    </div>
  </div>

  <!-- Right side => Element insertion-->
  <div class="d-flex flex-column bg-light ml-auto" style="width: 40%;">
    
    <div class="m-3 ">
      <label class="form-label" for="fileUploader"> Background</label>
      <button [disabled]="!thumbnail?.hasBackground()" class="btn btn-link text-primary" data-toggle="tooltip" data-placement="top" title="Update infrastructure background" (click)="updateThumbnail()">
        <mat-icon>refresh</mat-icon> Refresh infrastructure background
      </button>
    </div>
   
    <div class="mx-3">
      <label class="form-label" for="fileUploader"> Custom images</label>
      <input type="file" accept="image/*" #fileUploader hidden=true (change)="onFileChange($event)">
      <button class="btn btn-link text-primary" data-toggle="tooltip" data-placement="top" title="Add image from computer" (click)="fileUploader.click()">
        <mat-icon >upload_file</mat-icon> Insert image from local drive...
      </button>
    </div>

    <label class="form-label m-3" for="fileUploader"> Contextual images</label>
    <div class="list-group overflow-auto" style="max-height: 500px;" >
      <div *ngFor="let element of elements" class="insert-button">
        <a class="list-group-item list-group-item-action" (click)="selectElement(element)">
          <img class="insert-icon" [attr.src]="storeUrl+'/'+element?.sprite | secure | async" />
          {{element?.name}}
        </a>
      </div>
    </div>
  </div>
</div>



<div class="modal-footer">
  <div class="mr-auto d-flex">
    <input type="file" accept=".json" #fileUploaderJSON hidden=true (change)="onJSONChange($event)">
    <button class="btn btn-light" data-toggle="tooltip" data-placement="top" title="Import JSON illustration" (click)="fileUploaderJSON.click()">
      Import
    </button>
    <div ngbDropdown class="d-flex ">
      <button class="btn btn-light" id="dropdownExport" data-toggle="tooltip" data-placement="top" title="Export illustration as PNG or JSON" ngbDropdownToggle>Export</button>
      <div ngbDropdownMenu aria-labelledby="dropdownExport" class="dropdown-menu">
        <button class="dropdown-item form-control form-control-sm" (click)="export('PNG')" download>As PNG</button>
        <button class="dropdown-item form-control form-control-sm" (click)="export('JSON')" download>As JSON</button>
      </div>
    </div>
  </div>
  <button class="btn btn-light m-1" data-toggle="tooltip" data-placement="top" title="Discard change" (click)="closeWindow('Do you want to discard all changes ?')">
    <mat-icon>cancel</mat-icon>
    Discard
  </button>
  <button class="btn btn-success m-1" data-toggle="tooltip" data-placement="top" title="Save" (click)="save()">
    <mat-icon class="text-white">save</mat-icon>
    Save
  </button>
</div>



<div class="alert alert-warning mb-0" role="alert" *ngIf="isIE()">
  <mat-icon>warning</mat-icon>
  May not work properly on this browser
</div>