import { ThumbnailItemView } from '../thumbnail-item-view'
import { ThumbnailImage } from './thumbnail-image-model';


export class ThumbnailImageView extends ThumbnailItemView {

    protected item : ThumbnailImage;

    constructor(item : ThumbnailImage, parent : Element, id : number) {
        super(item, parent, id);
        this.setControlEnabled(ThumbnailItemView.DRAG_CONTROL, true);
        this.setControlEnabled(ThumbnailItemView.SELECTION_CONTROL, true);
        this.setControlEnabled(ThumbnailItemView.RESIZE_CONTROL, true);
        this.setControlEnabled(ThumbnailItemView.ROTATE_CONTROL, true);
    }

    public createItemView() : Element {
        const element = super.createItemView('image');
        element.setAttributeNS('http://www.w3.org/1999/xlink', 'href', this.item.getSource());
        element.setAttributeNS(null, 'preserveAspectRatio', 'none');
        return this.svgElement;
    }

}
