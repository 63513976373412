import { Pipe, PipeTransform } from "@angular/core";
import { DataModelService, ParamScheme } from "@mosar/mosar-dashboard-datamodel";
import { schemeForLogiclParam } from "app/use-case/utils-usecase";
import _ from "lodash";
import { HighLevelParameter, ParamValue, UseCaseParameter, ViewType } from "model/collection";
import { isObservable, of } from "rxjs";
import { startWith, catchError, map } from "rxjs/operators";
import { getAttributesFromPathBehavior, getAttributesFromPathEquipmentState } from "./utils-shared";

@Pipe({
  name: "wrapFn",
})
export class WrapFnPipe implements PipeTransform {
  transform(value: any, wrapperFunction, ...args): any {
    return wrapperFunction(value, ...args);
  }
}

/**
 * Return the scheme of the property referenced by the specified path
 */
@Pipe({
  name: "pathToScheme",
})
export class PathToSchemePipe implements PipeTransform {
  constructor(private dataModel: DataModelService) { }

  transform(path: any, className = "Scenario", scenario: any): any {
    if (!path) return this.dataModel.getClassProperty("MultiLevelParam");

    // Get all class or properties in the path
    const pathToProperties = path.replace(/\[(.*?)\]/g, "").split(".")
      .map((propertyName) => propertyName.replace(/(\[([0-9]+)*\])+/, ""));
    pathToProperties.shift(); // Remove $ character in first position

    // Get the scheme directly from the data model
    let paramScheme : ParamScheme;
    pathToProperties.forEach(propertyName => {    //FIXME: Pourquoi un forEach ici (traiter uniquement le dernier élément devrait suffire) ??
      const propertyScheme = this.dataModel.getParameters(className).find((param) => param.name == propertyName);
      className = propertyScheme?.className;
      const classScheme = this.dataModel.getClassProperty(className);
      // Take parameters value like scheme if property (usecase param) is not a class name
      if (!classScheme) {
        paramScheme = propertyScheme;
      } else {
        paramScheme = classScheme;
      }
    });

    // If the parameter is a ParamValue, the scheme must be constructed from the model
    if (paramScheme?.className === "ParamValue") {
      return this.getParamValueScheme(path, scenario);
    }
    return paramScheme;
  }

  private getParamValueScheme(path, scenario) : any {   // FIXME: Devrait retourner un ParamScheme
    let description = "";
    let abstractValues = [];
    let name = "";
    let properties = { ...schemeForLogiclParam.properties };
    properties.value = { ...properties.value };   //FIXME: initialisation de properties à revoir 

    // Logical parameters
    if (path.includes(`parameters`) && scenario?.parameters) {
      const parameter: HighLevelParameter = scenario.parameters?.find(
        (param) => {
          const idFromPath = +path.replace(/[^0-9]/g, "");
          if (Boolean(idFromPath)) {
            return param.id === idFromPath;
          }
          return false;
        }
      );

      properties.value.enum =
        parameter?.paramDescription?.abstractValues || [];
      properties.value.unit = parameter?.paramDescription?.unit
        ? [parameter.paramDescription.unit]
        : [];
      description = parameter?.paramDescription?.description;
      abstractValues = parameter?.paramDescription?.abstractValues || [];
      name = parameter?.paramDescription?.name || "Name not defined";
    }

    // Behavior states
    if (path.includes(`behaviorStates`)) {
      let attributes = getAttributesFromPathBehavior(path, scenario);
      if (attributes.isOK) {
        description = attributes.descriptionBehavior;
        properties.value.enum = attributes.abstractValuesBehavior;
        properties.value.unit = attributes.unit;
        name = attributes.nameBehavior;
      }
    }

    // Equipments states
    if (path.includes(`equipmentsState`)) {
      let attributes = getAttributesFromPathEquipmentState(path, scenario);
      if (attributes.isOK) {
        description = attributes.descriptionEquipment;
        properties.value.enum = attributes.abstractValuesEquipment;
        properties.value.unit = attributes.unit;
        name = attributes.nameEquipement;
      }
    }

    return {
      ...schemeForLogiclParam,
      properties, //FIXME: A quoi sert cette propriété ?
      enum: abstractValues,
      name,
      description: description
    };
  }
}

@Pipe({
  name: "schemeToAttribute",
})
export class SchemeToAttributePipe implements PipeTransform {
  transform(paramScheme: any, scenario: any, item: UseCaseParameter): any {
    if (!paramScheme || paramScheme.className !== "ParamValue") {
      return null;
    }
    if (scenario?.parameters?.length === 0) {
      return null;
    }

    let unitLabel = [];
    let abstractValues = [];

    if (scenario?.parameters && item.path.includes(`parameters`)) {
      const parameter: HighLevelParameter = scenario.parameters.find(
        (param) => {
          const idFromPath = +item.path.replace(/[^0-9]/g, "");
          if (Boolean(idFromPath)) {
            return param.id === idFromPath;
          }
        }
      );

      if (parameter) {
        unitLabel = [parameter?.paramDescription.unit];
        abstractValues = parameter?.paramDescription?.abstractValues;
      }
    }

    if (item.path.includes(`behaviorStates`)) {
      const {
        descriptionBehavior,
        abstractValuesBehavior,
        isOK,
        nameBehavior,
        unit,
      } = getAttributesFromPathBehavior(item.path, scenario);
      if (isOK) {
        abstractValues = abstractValuesBehavior;
        unitLabel = unit;
      }
    }
    if (item.path.includes(`equipmentsState`)) {
      const {
        descriptionEquipment,
        isOK,
        nameEquipement,
        unit,
        abstractValuesEquipment,
      } = getAttributesFromPathEquipmentState(item.path, scenario);
      if (isOK) {
        abstractValues = abstractValuesEquipment;
        unitLabel = unit;
      }
    }
    return {
      unitLabel,
      abstractValues,
    };
  }
}

@Pipe({
  name: "viewType",
})
export class ViewTypePipe implements PipeTransform {
  constructor(private dataModel: DataModelService) { }
  transform(property: any): any {
    if (!property) return "";

    if (property.className == "primitive") {
      if (property["enum"]) {
        return ViewType.ENUM;
      }
      if (property["minLength"]) {
        return ViewType.TEXT;
      }
      if (property["x-reference"]) {
        return ViewType.REF;
      }
      if (property["format"] == "file") {
        return ViewType.FILE;
      }
      switch (property.type) {
        case "boolean":
          return ViewType.BOOLEAN;
        case "number":
        case "integer":
          return ViewType.NUMBER;
        default:
          return ViewType.STRING;
      }
    }
    // ENUM
    else if (property.className == "enums") {
      return ViewType.ENUMS;
    }
    // Custom enum
    else if (property.className == "custom-enum") {
      return ViewType.CUSTOM_ENUM;
    }
    // Objects
    else if (property.className == "") {
      console.error("Empty class name");
      return "";
    } else {
      if (this.dataModel.isMultiLevelParam(property)) {
        return ViewType.MULTILEVEL;
      }
      if (property.type == "array") {
        return ViewType.ARRAY;
      }
      return ViewType.OBJECT;
    }
  }
}

@Pipe({
  name: "withLoading",
})
export class WithLoadingPipe implements PipeTransform {
  transform(val: any): any {
    return isObservable(val)
      ? val.pipe(
        map((value: any) => ({
          loading: value.type === "start",
          value: value.type ? value.value : value,
        })),
        startWith({ loading: true }),
        catchError((error) => of({ loading: false, error }))
      )
      : val;
  }
}

@Pipe({
  name: "simpleWithLoading",
})
export class SimpleWithLoadingPipe implements PipeTransform {
  transform(val) {
    return isObservable(val)
      ? val.pipe(
        map((value: any) => ({ loading: false, value })),
        startWith({ loading: true }),
        catchError((error) => of({ loading: false, error }))
      )
      : val;
  }
}

@Pipe({
  name: "joinTrigger",
})
export class JoinTriggerPipe implements PipeTransform {
  transform(triggers = []) {
    return triggers && triggers.length
      ? triggers.map((trigger) => trigger.name || "No name").join(", ")
      : triggers;
  }
}

@Pipe({
  name: "formatCombinatorial",
})
export class FormatCombinatorialPipe implements PipeTransform {
  transform(combinatory = [], value = "") {
    if (!combinatory || !combinatory.length) return "";
    let result = "";
    if (value === "concrete") {
      return `Concrete value : { ${combinatory
        .map((c) => c.value.concrete)
        .join(", ")} }`;
    }
    if (value === "abstracted") {
      return `Abstracted value : { ${combinatory
        .map((c) => c.value.abstracted)
        .join(", ")} }`;
    }
    if (value === "range") {
      return `Range value : { ${combinatory
        .map(
          (itemRange) =>
            "[" +
            itemRange.value.min +
            ", " +
            itemRange.value.max +
            "] / " +
            itemRange.variationStep
        )
        .join(", ")} }`;
    }

    let ranges = [];
    let concretes = [];
    let abstracteds = [];
    let unit = "";
    combinatory.map(({ value, variationStep }) => {
      if ("min" in value || "max" in value) {
        ranges.push(`[${value.min}, ${value.max}] / ${variationStep} `);
      }

      if ("concrete" in value) {
        concretes.push(value.concrete);
        unit = value.unit;
      }

      if ("abstracted" in value) {
        abstracteds.push(value.abstracted);
      }
    });
    if (ranges.length) {
      result = `{${ranges.join(", ")}}<br>`;
    }
    if (concretes.length) {
      result += `{${concretes.join(", ")}} <b>${unit}</b><br>`;
    }
    if (abstracteds.length) {
      result += `{${abstracteds.join(", ")}}`;
    }
    return result;
  }
}

@Pipe({
  name: "formatTolerance",
})
export class FormatTolerancePipe implements PipeTransform {
  transform(tolerance: { min, max}) {
    // No values defined
    if (!tolerance || (tolerance.min == undefined && tolerance.max == undefined)) {
      return "undefined";
    }

    // Min and max are the same
    if (tolerance.max === tolerance.min) {
      return `± ${tolerance.max}`;
    }

    // Min and max are different (both sould be defined)
    let minLabel = tolerance.min != undefined ? `-${tolerance.min}` : 'undefined';
    let maxLabel = tolerance.max != undefined ? `+${tolerance.max}` : 'undefined';
    return `${minLabel} to ${maxLabel}`;
  }
}

@Pipe({
  name: "filterParameter",
})
export class FilterParameterPipe implements PipeTransform {
  transform(parameters = [], measurement, idActor, idStep) {
    if (measurement) {
      parameters = parameters.filter((param) =>
        param.measurement.includes(measurement)
      );
    }

    if (idStep) {
      parameters = parameters.filter((param) => {
        const existId = param.references.find((val) => val.id === idStep);
        return Boolean(existId);
      });
    }

    if (idActor) {
      parameters = parameters.filter((param) => {
        const existId = param.references.find((val) => val.id === idActor);
        return Boolean(existId);
      });
    }
    return parameters;
  }
}

@Pipe({
  name: "subtitleLibrary",
})
export class SubtitleLibraryPipe implements PipeTransform {
  transform(library, nameApi = "") {
    if (!nameApi || !library) return "";
    if (nameApi === "usecase") {
      return library?.scenario?.name || "";
    }

    if (nameApi === "testprotocol" && library.testScenarios?.length) {
      const nbreUsecaseToDisplay = 4;
      return (
        library.testScenarios
          .map((testScenario) => testScenario.usecase.name)
          ?.slice(0, nbreUsecaseToDisplay - 1)
          ?.join(", ") || ""
      );
    }

    return "";
  }
}
