import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AppStatusService } from "../../../service/app-status/app-status.service";
import { Location } from "@angular/common";
import { ContainerService } from "../container.service";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  pathItems = [];
  pathItemsLabels = [];
  isHomePage: boolean = false;
  isSelectedMenuUseCase = false;
  isSelectedMenuTestProtocol = false;
  isSelectedMenuScenario = false;
  isSelectedMenuCollection = false;

  constructor(
    public appStatus: AppStatusService,
    private location: Location,
    private containerService: ContainerService,
    private router: Router,
    private changeDedectionRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Reaction to routing events: update sections and breadcrumb display 
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.updateCurrentSelectedSection(this.location.path());
        this.pathItems = this.getPathItems(this.location.path());
        for (let itemIndex = 0; itemIndex < this.pathItems.length; itemIndex++) {
          this.updatePathItemLabelAsync(this.pathItems[itemIndex].label, itemIndex);
        }
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  /**
   * Construct the path tree from the current location
   */
  private getPathItems(locationPath : string) {
    let pathStack = locationPath.split("?")[0].split("/");
    pathStack = pathStack.filter((p) => p != "home");
    pathStack.shift();
    return pathStack.map((pathItem, itemIndex) => {
      return { 
        route: "/" + pathStack.slice(0, itemIndex + 1).join("/"),
        label: pathItem 
      };
    });
  }

  /**
   * Update flags indicating which section is currently selected
   * @param currentPath Current routing path
   */
  private updateCurrentSelectedSection(currentPath : string) {
    if(currentPath == ('/home') || currentPath.includes('scenario')) {
      this.isSelectedMenuScenario = true;
      this.isSelectedMenuTestProtocol = false;
      this.isSelectedMenuUseCase = false;
      this.isSelectedMenuCollection = false;
    }
    else if(currentPath.includes('testprotocol')) {
      this.isSelectedMenuTestProtocol = true;
      this.isSelectedMenuScenario = false;
      this.isSelectedMenuUseCase = false;
      this.isSelectedMenuCollection = false;
    }
    else if(currentPath.includes('usecase') || currentPath.includes('system')) {
      this.isSelectedMenuUseCase = true;
      this.isSelectedMenuTestProtocol = false;
      this.isSelectedMenuScenario = false;
      this.isSelectedMenuCollection = false;
    }
    else if(currentPath.includes('collections')) {
      this.isSelectedMenuUseCase = false;
      this.isSelectedMenuTestProtocol = false;
      this.isSelectedMenuScenario = false;
      this.isSelectedMenuCollection = true;
    } 
    
    if (currentPath.includes('/home') || currentPath == '/collections') {
      this.isHomePage = true;
    } 
    else {
      this.isHomePage = false;
    }
  }

  //FIXME: A retirer lors du refactoring #9602
  NAMES_API_COLLECTION = [
    "usecase",
    "testprotocol",
    "actor",
    "infrastructure",
    "infrastructureelement",
    "behavior",
    "equipment",
    "tag",
    "drivingautomationsystem",
    "scenarios"
  ];

  /**
   * Change the label of some path items to be more human relevant
   * @param itemValue 
   * @param pathItemIndex 
   */
  private updatePathItemLabelAsync(itemValue, pathItemIndex) {
    // Change the label of path items that are a resource identifier by the name of the referenced resource
    let previousItemLabel = this.pathItems[pathItemIndex - 1] ? this.pathItems[pathItemIndex - 1].label : null;
    if (previousItemLabel && this.NAMES_API_COLLECTION.includes(previousItemLabel)) {
      let collectionName = previousItemLabel == 'scenarios' ? 'scenario' : previousItemLabel;   //FIXME: scenarios vs scenario => #9602
      let container = this.containerService.getLocalContainerById(collectionName, itemValue);
      if (container) {
        this.pathItemsLabels[pathItemIndex] = container.resources[0].name;
      }
      else {
        this.containerService
          .getContainerById(collectionName, itemValue)
          .subscribe((resp) => {
            this.pathItemsLabels[pathItemIndex] = resp.resources[0].name;
          });
      }
    } else {
      this.pathItemsLabels[pathItemIndex] = itemValue;
    }

    this.applyLabelWorkarounds(pathItemIndex);
  }

  //FIXME: #9602: Following code are workarounds, the implementation should be refactored so that the 
  // labels are directly written using static collections attributes
  private applyLabelWorkarounds(pathItemIndex) {
    // Unconcatenate some collections names
    if (this.pathItemsLabels[pathItemIndex] === "infrastructureelement") {
      this.pathItemsLabels[pathItemIndex] = "infrastructure element";
    }
    if (this.pathItemsLabels[pathItemIndex] === "drivingautomationsystem") {
      this.pathItemsLabels[pathItemIndex] = "driving automation system";
    }

    // Change the wording 'collections' to 'frames of reference'
    if (this.pathItemsLabels[pathItemIndex] === "collections") {
      this.pathItemsLabels[pathItemIndex] = "frames of reference";
    }

    // Suffix upper level of sections with "containers"
    if (this.pathItemsLabels[pathItemIndex] == 'driving automation system' || 
          this.pathItemsLabels[pathItemIndex] == 'usecase' || 
          this.pathItemsLabels[pathItemIndex] == 'testprotocol' || 
          this.pathItemsLabels[pathItemIndex] == 'scenarios') {
      this.pathItemsLabels[pathItemIndex] += ' containers';
    } 
  }

}
