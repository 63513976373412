import { Injectable } from '@angular/core';
import { DataModelService, ParamScheme } from '@mosar/mosar-dashboard-datamodel';
import { ConfigureService } from 'service/ng4-configure/ng4-configure.service';


const DOCUMENTATION_ROOT_LINK = 'MOSAR-Scenario-DataModel-Reference.html#_';


/**
 * Service that create documentation link for a given property.
 * Each methods return an URL or '' if no link can be computed.
 * FIXME: To move in DataModel lib ?
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentationLinkService {

  constructor(private dataModel : DataModelService, private configService: ConfigureService) {
  }

  public fromPropertyScheme(objectClass: string, propertyScheme: ParamScheme) {
    let objectScheme = this.dataModel.getClassProperty(objectClass);
    return this.fromObjetAndPropertySchemes(objectScheme, propertyScheme);
  }

  public fromObjetAndPropertySchemes(objectScheme: ParamScheme, propertyScheme: ParamScheme) {
    if (objectScheme) {
      let docClass = objectScheme.className;
      if (propertyScheme.type == 'object' && propertyScheme['viewType'] != 'multilevel' && propertyScheme.className != 'primitive')
        docClass = propertyScheme.className;
      return this.configService.config.documentationUrl + DOCUMENTATION_ROOT_LINK + docClass.toLowerCase();
    }
    return '';
  }

}
