import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { KeycloakService } from 'keycloak-angular';
import { ObserversModule } from '@angular/cdk/observers';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private cacheService: CacheService, private keycloakService: KeycloakService) { }
  
  //FIXME: Restore this implementation (don't add keycloak token here).
  //       The token should be added by the keycloak interceptor, but it 
  //       refuses to intercept requests from the Shared module.
  //
  // transform(url: string, isfile?: boolean) {
  //
  //   return new Observable(observer => {
  //     if (this.cacheService.getCachedFile(url)) {
  //       observer.next(this.cacheService.getCachedFile(url));
  //     } else {
  //       if (!isfile)
  //         observer.next("assets/images/loading-gif.gif");
  //       this.http.get(url, { responseType: 'blob', headers: { Accept: 'application/*' } })
  //         .pipe(map(val => isfile ? val : this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))))
  //         .toPromise()
  //         .then(res => {
  //           this.cacheService.addCachedFile(res, url);
  //           observer.next(res);
  //         })
  //         .catch(e => observer.error(e));
  //     }
  //   })
  // }

  transform(url: string, isfile?: boolean) {

    return new Observable(observer => {
      if (this.cacheService.getCachedFile(url)) {
        observer.next(this.cacheService.getCachedFile(url));
      } else {
        if (!isfile)
          observer.next("assets/images/loading-gif.gif");
        this.getToken().then(token => {
          let headers = new HttpHeaders({ Accept: 'application/*', Authorization: 'Bearer ' + token });

          this.http.get(url, { responseType: 'blob', headers: headers })
          .pipe(map(val => isfile ? val : this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))))
          .toPromise()
          .then(res => {
            this.cacheService.addCachedFile(res, url);
            observer.next(res);
          })
          .catch(e => {
            observer.next('');
            return observer.error(e)
          });
        });
      }
    })
  }

  private async getToken() {
    return await this.keycloakService.getToken();
  }

}
