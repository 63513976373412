<ng-template #tooltipContent>
  <img *ngIf="image" class="p-1 float-right" src="./assets/images/{{ image }}" loadImage alt="image" />
  <p>
    <b>{{ doc.heading }}</b>
    <span *ngIf="doc.body" class="multiline">{{ doc.body }}</span>
  </p>
  <ul>
    <li *ngFor="let item of doc.enums">
      <b>{{ item.name }}</b>: {{ item.description }}
    </li>
  </ul>
  <p>
    <small><mat-icon>documentation</mat-icon>&nbsp;<i>Click to access documentation</i></small>
  </p>
</ng-template>

<a href="{{ link }}" target="_blank" 
      [ngbPopover]="tooltipContent" 
      triggers="hover"
      popoverTitle="{{ label }}"
      container="body" 
      popoverClass="{{ image || doc.enums.length > 4 ? 'big-popover' : (doc.heading.length < 50 ? 'small-popover' : 'medium-popover') }}">
  <mat-icon container="body">help</mat-icon>
</a>