<div class="d-flex justify-content-center align-items-center vw-100 vh-100">
    <div class="text-center border p-5">
        <h3>Error Loading ADSCENE Dashboard</h3>
        <p>Please reload or contact support:
            <br>
            <a href="mailto:support-adscene.fr@capgemini.com">support-adscene.fr@capgemini.com</a>
        </p>
        <button class="btn btn-lg btn-secondary mx-2" onClick="location.reload()"><mat-icon>refresh</mat-icon></button>
    </div>
</div>
