import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { DataModelService, ParamScheme } from '@mosar/mosar-dashboard-datamodel';
import { PropertiesFilter } from 'pipes/properties-filter.pipe';

@Component({
  selector: 'app-lateralposition-view',
  templateUrl: './lateralposition-view.component.html',
  styleUrls: ['./lateralposition-view.component.scss']
})
export class LateralpositionViewComponent implements OnInit, OnChanges {
  @Input('path') path : string; // path of the param
  @Input('stepId') stepId : any; 
  @Input('actorId') actorId : string; 
  @Input('annotations') annotations : any;
  @Input('stepIndex') stepIndex : any;
  @Input('context') context : any = null; // Scenario / infrastructure or actor, usefull in case of reference
  @Input('readonly') readonly : boolean = false;
  @Input('scheme') scheme : ParamScheme;  // Data scheme
  @Input('model') model : any;            // Data value
  @Input('prevModel') prevModel : any = null; // Previous data value, used if param in array to highlight changes
  @Input('level') level = 0;  // Indentation level
  @Input('lastUpdatedAttr') lastUpdatedAttr: any;
  @Input('formulaEnabled') formulaEnabled: boolean;
  @Input('statistics') statistics: boolean;

  @Output() propagatedEvent = new EventEmitter<any>();
  @Output() lastUpdatedEvent = new EventEmitter<any>();
  @Input('update') update = new EventEmitter<any>();

  public subProps: Map<string, ParamScheme> = new Map(); // Implicitly required by sub component "reference-view"


  schemefilter: any;
  isPositionRelative: boolean = false;
  isShiftRelative: boolean = false;
  positionTypeScheme : ParamScheme;


  constructor(private dataModel : DataModelService) {
    this.positionTypeScheme = this.dataModel.getParameters('LateralPosition').find(param => param.name == "positionType");
  }

  ngOnInit() {
    for (let sp of this.dataModel.getParameters(this.scheme.className))
      this.subProps[sp.name] = sp;
  }

  ngOnChanges(){
    // Adapth displayed properties according to the context
    let newschemefilter = (new PropertiesFilter())
      .addRemovedProperty('segment')
      .addRemovedProperty('strip')
      .addRemovedProperty('path')
      .addRemovedProperty('positionType');
    
    if (!this.model['positionType'] || this.model['positionType'] == 'ABSOLUTE') {
      // Absolute positionment
      this.isPositionRelative = false;
      this.isShiftRelative = false;
      newschemefilter.addRemovedProperty('position');
      newschemefilter.addRemovedProperty('reference');
      newschemefilter.addRemovedProperty('relativeShift');
      delete this.model.reference;
      delete this.model.position;
      delete this.model.relativeShift;
    }
    else if (this.model['positionType'] == 'RELATIVE_LANE') {
      // Relative positionment
      newschemefilter.addRemovedProperty('relativeShift');
      this.isPositionRelative = true;
      this.isShiftRelative = false;
      delete this.model.segment;
      delete this.model.strip;
      delete this.model.relativeShift;
    }
    else if (this.model['positionType'] == 'RELATIVE_DISTANCE') {
      // Relative positionment
      newschemefilter.addRemovedProperty('position');
      newschemefilter.addRemovedProperty('shift');
      this.isPositionRelative = true;
      this.isShiftRelative = true;
      delete this.model.segment;
      delete this.model.position;
      delete this.model.strip;
      delete this.model.shift;
    }
    this.schemefilter  = newschemefilter;

    // Show 'Lane' attributes only if positining refers a strip with lanes variability
    if (!this.stripHasSeveralLanes()) {
      newschemefilter.addRemovedProperty('lane');
      delete this.model.lane;
    }

    // Manage intersection and paths
    if(this.isIntersection())
      delete this.model.strip;
    else
      delete this.model.path;
    this.update.emit(true);
  }

  public propagateValue(attr) {
    this.propagatedEvent.emit(attr);
  }

  public setLastUpdatedAttr(lastUpdatedAttr, name) {
    if(lastUpdatedAttr && name)
      lastUpdatedAttr.attr = name + '.' + lastUpdatedAttr.attr;
    this.lastUpdatedEvent.emit(lastUpdatedAttr);
  }

  isIntersection(model?: any): boolean {
    try {
      if (!model)
        model = this.model
      return this.context.infrastructure.segments.find(s => s.id == model.segment).type == 'INTERSECTION'
    } catch {
      return false
    }
  }

  stripHasSeveralLanes(): boolean {
    try {
      let segment = this.context.infrastructure.segments.find(seg => seg.id == this.model.segment);
      let strip = segment.strips.find(s => s.id == this.model.strip);
      return ('value' in strip.stripVariability.amount);
    } catch {
      return false
    }
  }
}
