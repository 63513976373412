import { Component, EventEmitter, Input, Output, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input('confirm-label') confirmLabel = "Ok";
  @Input('icon') icon = "question_answer";
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();
  @ViewChild("dialogTemplate", { static: true }) private dialogModal: TemplateRef<any>;
  @Input() disabled = false;

  constructor(private modalService: NgbModal) {
  }

  show(content) {
    this.modalService.open(this.dialogModal).result.then((result) => {
      this.confirmed.emit(true);
    }, (reason) => { 
      this.cancel.emit(true)
    });
  }
}
