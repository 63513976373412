export interface CollectionInfo {
  label: string;
  link: string;
  description: string;
  doc: string;
  showReport: boolean;
  showTree: boolean;
}

export interface ContainerInfo {
  title: string;
  subtitle: string;
  link: string;
  shortName: string;
  nameApi: string;
  titleStats: string;
  showStats: boolean;
  showContainerStatistic: boolean;
  showSearch: boolean;
}

export class VersionInfos {
  dashboardVersion = "";
  statisticsVersion = "";
  datamodelVersion = "";
  apiUrl = "";
  apiVersion = "";
  authUrl = "";
  backendVersion = "";
}

export interface ItemMetaData {
  /**
   * Version of this item
   */
  version?: string;
  /**
   * Date of creation of this scenario
   */
  date?: string;
  /**
   * Edition status of the use-case.  DRAFT - A draft item is an item that is still under construction FEEDBACK - A Feedback item is an item that cannot be validated with the actual version of the tool, or the scenario has to be modified after verification (Ready status) READY - A Ready item is an item ready to be verified VALIDATED - A validated item is an item that has been ready, verified, and validated
   */
  stateValidation?: string;
  /**
   * User that creates this item
   */
  author?: string;
  /**
   * Version of the ScenarioManager data model in which the use-case is encoded
   */
  dataModelVersion?: string;
}

/**
 * Mapping between a variable name used in a formula and the corresponding model parameter
 */
export interface VariableMapping {
  /**
   * The name as used in the formula (must contain only letters and avoid math expression such as cos, sqrt, ...
   */
  name: string;
  /**
   * The JSONPath expression for mapping (Root document is the parent #definition/Scenario)
   */
  jsonpath?: string;
}

/**
 * Scenario parameter object that can hold a value on multiple abstraction level
 */
export interface MultiLevelParam {
  /**
   * Unit in which is expressed the specified value. If not defined, the first possible unit of the mlp is used.
   */
  unit?: string;
  abstracted?: string;
  concrete?: number;
  min?: number;
  minExclusive?: boolean;
  max?: number;
  maxExclusive?: boolean;
  /**
   * The value, expressed as a number or as a math expression computable by the MXparser library. ex: 'x^2 + sin(y)', x and y must be provided in the 'variables' parameter of this object.
   */
  formula?: string;
  primitive?: string;
  /**
   * The list of variables used in the 'formula'
   */
  variables?: Array<VariableMapping>;
}

/**
 * Reference to a static object or an actor
 */
export interface ObjectReference {
  /**
   * Reference to a static object or an actor.
   */
  value?: number;
}

/**
 * Parameter Value
 */
export interface ParamValue {
  value?: MultiLevelParam;
  reference?: ObjectReference;
}

/**
 * Parameter that is specific to a given scenario
 */
export interface HighLevelParameter {
  id?: number;
  paramDescription?: ParamDescription;
  value?: ParamValue;
}

/**
 * Reference to a scenario, with a specific version and an optional comment.
 */
export interface ScenarioReference {
  id: number;
  /**
   * Version of the scenario. This information can be used to ensure that the scenario did not change since this reference was created.
   */
  version?: string;
  /**
   * Commentary linked with the creation/modification
   */
  comment?: string;
  /**
   * Representation of this model parents ([...]/grandparent/parent/this)
   */
  path?: string;
  /**
   * Name to the scenario
   */
  name?: string;
  idContainer?: Number;
}

/**
 * Parameter of a use-case, used for simulation purposes
 */
export interface UseCaseParameter {
  /**
   * unique id
   */
  id?: number;
  /**
   * Type of use-case parameter  INPUT - The parameter is a variable parameter of the use-case TRIGGER - The parameter defines a trigger of a scene of the scenario EXPECTED - The parameters defines the expected behavior of the system under test
   */
  type?: any;
  /**
   * Name to the parameter
   */
  name?: string;
  /**
   * Location of the parameter inside the scenario
   */
  path: string;
  nominalValue?: MultiLevelParam;
  value?: MultiLevelParam;
  /**
   * Commentary linked with the creation/modification
   */
  description?: string;
}

/**
 * Reference to a UseCaseParameter, with it's value
 */
export interface UseCaseParameterReference {
  /**
   * reference to a UseCaseParameter
   */
  id?: number;
  value?: MultiLevelParam | any;
}

/**
 * A combination of usecase parameters
 */
export interface UseCaseParametersSubset {
  usecaseParameterList?: Array<UseCaseParameterReference>;
}

export const initialStateUsecase = {
  tags: [],
  attachments: [],
  systems: [],
  expectedBehavior: "",
  usecaseParameters: [],
  relevantCases: [],
  traceability: { database: "", originId: "", source: "" },
  relevantSubsets: [],
  scenario: { id: null, comment: "", version: "", path: "" },
  image: "",
};
export interface UseCase {
  /**
   * Identifier of the use-case. ID generated by application.
   */
  id?: number;
  metadata?: ItemMetaData;
  /**
   * Name of the use-case
   */
  name: string;
  /**
   * Detailed description of the use-case
   */
  description?: string;
  /**
   * Global view of the usecase
   */
  image?: string;
  traceability?: Traceability;
  /**
   * Description of the expected behavior of a system subjected to this use-case.
   */
  expectedBehavior?: string;
  attachments?: Array<Attachment>;
  tags?: Array<Tag>;
  scenario?: any;
  systems?: Array<AutomatedDrivingSystem>;
  usecaseParameters?: Array<UseCaseParameter>;
  parameters?: Array<HighLevelParameter>;
  /**
   * Relevant cases for this use-case
   */
  relevantCases?: Array<ScenarioReference>;
  /**
   * A list of relevant combinations of parameters
   */
  relevantSubsets?: Array<UseCaseParametersSubset>;
}

export interface Attachment {
  filename: string;
  file: string;
  description: string;
}

export interface ParamDescription {
  type: string;
  name: string;
  description: string;
  unit: string;
  abstractValues: string[];
}

export interface Reference {
  value: number;
}

export interface Tag {
  id: number;
  parent: number;
  path: string;
  attachment: Partial<Attachment>;
  name: string;
  description: string;
  sprite: string;
}

export interface Traceability {
  source: string;
  database: string;
  originId: string;
}

export interface FilterFormUsecase {
  name: string;
  scenario: any;
  system: any;
  source: any;
}

export interface FilterContainer {
  field?: string;
  operator?: string;
  value?: string | string[];
  customType?: boolean;
}

export interface FiltersContainer {
  list?: Array<FilterContainer>;
}

export interface UseCaseDatas {
  idContainer: Number;
  usecases: Partial<UseCase[]>;
  permissions: any;
  totalResults: Number;
}

export interface UseCaseData {
  idContainer: Number;
  usecase: Partial<UseCase>;
  permissions: any;
  totalResults: Number;
}

/**
 * Autonomous driving system
 */
export interface AutomatedDrivingSystem {
  /**
   * Identifier of the system
   */
  id?: number;
  /**
   * Identifier of the parent
   */
  parent?: number;
  /**
   * Representation of this model parents ([...]/grandparent/parent/this)
   */
  path?: string;
  /**
   * Name of the system
   */
  name: string;
  /**
   * Description and remarks about this system
   */
  description?: string;
  /**
   * Picture illustrating the system
   */
  image?: string;
  /**
   * Representation of the system on an overview
   */
  sprite?: string;
  attachment?: Attachment;
  parameters?: Array<ParamDescription>;
}

export interface HomeContainerData {
  containerInfo: ContainerInfo;
  containers: any[];
  err: string;
}

export interface HomeStatisticData {
  containerInfo: ContainerInfo;
  pieChartData: any;
  pieChartLabels: any[];
  errorStat: string;
}

export enum ViewType {
  STRING = "string",
  NUMBER = "number",
  TEXT = "text",
  ENUM = "enum",
  BOOLEAN = "boolean",
  OBJECT = "object",
  MULTILEVEL = "multilevel",
  FILE = "file",
  REF = "ref",
  ARRAY = "array",
  ENUMS = "enums",
  CUSTOM_ENUM = "custom-enum",
}
