import { Component, OnInit, Input, EventEmitter } from "@angular/core";
import { CollectionService } from "app/collection/collection.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SVGToolkitService } from "./svg-toolkit-service";
import { Thumbnail } from "./thumbnail-model";
import { ThumbnailSVGView } from "./thumbnail-svg-view";
import { ThumbnailImage } from "./thumbnail-items/thumbnail-image/thumbnail-image-model";
import { ThumbnailCurve } from "./thumbnail-items/thumbnail-curve/thumbnail-curve-model";
import { ThumbnailText } from "./thumbnail-items/thumbnail-text/thumbnail-text-model";
import { ThumbnailItemTFunction } from "./thumbnail-items/thumbnail-item-model";
import { ThumbnailLine } from "./thumbnail-items/thumbnail-line/thumbnail-line-model";
import { ImportExportService } from "../import-export.service";
import { ConfirmationDialogComponent } from "app/shared/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "ngbd-modal-content",
  template: `

    <div class="modal-body">
      <p>{{ content }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="activeModal.close()"
      >
        OK
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.dismiss()"
      >
        Cancel
      </button>
    </div>
  `,
})
export class NgbdModalContent {
  @Input() content: string;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: "thumbnailgenerator",
  templateUrl: "./thumbnailgenerator.component.html",
  styleUrls: ["./thumbnailgenerator.component.scss"],
})
export class ThumbnailgeneratorComponent implements OnInit {
  @Input("background") background;
  @Input("elements") elements = [];
  @Input("onSubmit") onSubmit = new EventEmitter();
  @Input("model") model: Thumbnail;
  @Input("errorPicturable") errorPicturable = false;

  public thumbnail = new Thumbnail();
  public prevThumbs: Thumbnail[] = [];
  public viewbox = { x: 0, y: 0, w: 600, h: 400 };
  public svgView: ThumbnailSVGView;
  public color: string = "#000000";
  imgDimensions;

  storeUrl: string;
  private hasChanged = false;

  ratios = [
    {
      label: "4/3",
      value: 4 / 3,
      valueFixed: "1.3",
    },
    {
      label: "16/9",
      value: 16 / 9,
      valueFixed: "1.7",
    },
    {
      label: "2,39/1",
      value: 2.39 / 1,
      valueFixed: "2.3",
    },
    {
      label: "32/9",
      value: 3.5,
      valueFixed: "3.5",
    },
  ];

  // count of elements
  selectedIndex = 0;

  constructor(
    private collectionService: CollectionService,
    public activeModal: NgbActiveModal,
    private svgToolkit: SVGToolkitService,
    private importexportService: ImportExportService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.storeUrl = this.collectionService.getStepImageStoreUrl();

    this.svgView = new ThumbnailSVGView(
      this.thumbnail,
      document.querySelector("#thumb-container")
    );

    const OnChange = () => {
      this.subEventOnChange(this.thumbnail);
    };
    OnChange();

    if (
      this.background &&
      this.background.image &&
      (this.errorPicturable || !this.model.hasBackground())
    ) {
      this.updateThumbnail();
    }

    this.load(this.model);
    this.createCropper();
  }

  load(model: Thumbnail) {
    if (model) {
      for (let item of model.getItems()) {
        this.subEventOnChange(item);
        this.thumbnail.addItem(item);
      }

      this.thumbnail.size = this.svgView.sizeToCrop = this.model.size;
      this.thumbnail.ratio = this.model.ratio;
      this.svgView.selectedRatio =
        this.ratios.find((ratio) => ratio.label === this.thumbnail.ratio)
          ?.value || 3.5;

      this.svgView.resetSelection();
    }
  }

  save() {
    // reset the viewBox to get the not zoomed pic
    let svg = document.querySelector("#thumb-container");
    svg.setAttribute("viewBox", 0 + " " + 0 + " " + 600 + " " + 400);
    // Remove the cropper for the picture
    let cropper = document.getElementById("cropper");
    cropper.setAttribute("style", "visibility:hidden;");

    let dimension = this.svgView.getDimention();
    this.svgView.resetSelection();
    this.svgToolkit.createImage(
      document.querySelector("#thumb-container"),
      dimension.width,
      dimension.height,
      this.onSubmit
    );
  }

  // Subscribe item on the OnChange event & Save state of the thumbnail on event
  subEventOnChange(item) {
    item.onChange.subscribe((r) => {
      // Limite of 10 previous state saved
      if (this.prevThumbs.length >= 10) {
        this.prevThumbs.pop();
      }
      this.prevThumbs.unshift(this.thumbnail.copy());
      this.hasChanged = true;
    });
  }

  isIE() {
    return (
      navigator.userAgent.indexOf("MSIE ") > -1 ||
      navigator.userAgent.indexOf("Trident/") > -1 ||
      navigator.userAgent.indexOf("Edge/") > -1
    );
  }

  // select element from dropdown
  selectElement(elem) {
    this.svgToolkit.urlToBase64(
      elem.sprite
        ? `${this.storeUrl}/${elem.sprite}`
        : `../../assets/images/unavailable-xs.png`,
      (source) => {
        this.svgToolkit.imageDimensions(source).then((r) => {
          let newItem = new ThumbnailImage({ ...r, source, url: elem.sprite });
          this.subEventOnChange(newItem);
          this.thumbnail.addItem(newItem);
          this.createCropper();
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  // upload file
  onFileChange(event) {
    let data = new FormData();
    data.append("imageFile", event.target.files[0]);
    this.collectionService.storeStepImage(data).then((res: any) => {
      this.svgToolkit.fileToBase64(
        event.target.files[0],
        (source) => {
          this.svgToolkit.imageDimensions(source).then((r) => {
            let newItem = new ThumbnailImage({ ...r, source, url: res.newid });
            this.subEventOnChange(newItem);
            this.thumbnail.addItem(newItem);
            this.createCropper();
          });
          // Reset files on input
          event.target.type = "";
          event.target.type = "file";
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }

  onJSONChange(event) {
    var reader = new FileReader();
    reader.onload = function (e) {
      let obj = JSON.parse(<string>e.target.result);
      newTh(obj);
    };
    const newTh = (data: any) => {
      this.importexportService.importThumbnail(data).then((thumbnail) => {
        this.thumbnail.removeAllItems();
        this.load(thumbnail);
        this.createCropper();
      });
    };

    reader.readAsText(event.target.files[0]);
  }

  // download file
  export(str: string) {
    // reset the viewBox to get the not zoomed pic
    let svg = document.querySelector("#thumb-container");
    svg.setAttribute("viewBox", 0 + " " + 0 + " " + 600 + " " + 400);
    // Remove the cropper for the picture
    let cropper = document.getElementById("cropper");
    cropper.setAttribute("style", "visibility:hidden;");
    this.svgView.resetSelection();

    if (str == "JSON") {
      let data = this.importexportService.exportThumbnail(this.thumbnail);
      let json = JSON.stringify(data);
      let blob = new Blob([json], { type: "octet/stream" });
      this.downloadFile(blob, ".json");
      cropper.setAttribute(
        "style",
        "visibility:visible;pointer-events:none;stroke-width:3"
      );
    } else if (str == "PNG") {
      let ev = new EventEmitter();
      ev.subscribe((elt) => {
        this.downloadFile(elt, ".png");
        cropper.setAttribute(
          "style",
          "visibility:visible;pointer-events:none;stroke-width:3"
        );
      });

      let dimension = this.svgView.getDimention();
      this.svgToolkit.createImage(
        document.querySelector("#thumb-container"),
        dimension.width,
        dimension.height,
        ev
      );
    }
  }

  // Download popup for file
  downloadFile(elt, type?: string) {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(elt);
    if (type == undefined) {
      a.setAttribute("download", "MOSAR_" + "vignette");
    } else {
      a.setAttribute("download", "MOSAR_" + "vignette" + type);
    }

    a.setAttribute("target", "_blank");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // Create path line
  createCurve() {
    let newItem = new ThumbnailCurve();
    this.subEventOnChange(newItem);
    this.thumbnail.addItem(newItem);
    this.createCropper();
  }

  // Add text to the svg view
  createAnnotate() {
    let newItem = new ThumbnailText();
    this.subEventOnChange(newItem);
    this.thumbnail.addItem(newItem);
    this.createCropper();
  }

  // Draw line with mouse in svg view
  createLine(type: String) {
    let item = {
      type: type,
    };
    let newItem = new ThumbnailLine(item);
    this.subEventOnChange(newItem);
    this.thumbnail.addItem(newItem);
    this.createCropper();
  }

  /**
   * Draw a rect which simulate the picture to crop
   * Should be called at each svg modification to be over all the elements
   */
  createCropper(r?) {
    let svg = document.getElementById("thumb-container");

    // Set ratio and size thumbnail
    this.thumbnail.size = this.svgView.sizeToCrop;
    this.thumbnail.ratio = this.ratios.find(
      (ratio) =>
        ratio.valueFixed ===
        this.svgView.selectedRatio.toString().substring(0, 3)
    )?.label;

    // Remove the old cropper, important to allow the cropper to be at the highest z-index
    let testcropper = document.getElementById("cropper");
    if (testcropper != undefined) {
      svg.removeChild(testcropper);
    }

    let cropper = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect"
    );

    let w = this.svgView.getDimention().width;
    let h = this.svgView.getDimention().height;
    let x = 300 - w / 2;
    let y = 200 - h / 2;
    cropper.setAttribute("id", "cropper");
    cropper.setAttribute("fill-opacity", "0");
    cropper.setAttribute("stroke", "#525151");
    cropper.setAttribute("stroke-dasharray", "4, 1");
    cropper.setAttribute("style", "pointer-events:none;stroke-width:3");
    cropper.setAttribute("x", x.toString());
    cropper.setAttribute("y", y.toString());
    cropper.setAttribute("width", w.toString());
    cropper.setAttribute("height", h.toString());
    svg.appendChild(cropper);
  }

  // Restore previous state of the thumbnail
  restorePrevState() {
    if (this.prevThumbs.length > 0) {
      let temp = this.prevThumbs.shift();
      for (let elt of this.thumbnail.getItems()) {
        this.thumbnail.removeItemPrev(elt);
      }
      for (let item of temp.getItems()) {
        this.subEventOnChange(item);
        this.thumbnail.addItemPrev(item);
      }
      this.createCropper();
    }
  }

  deleteCachePrevState() {
    this.prevThumbs = [];
  }

  // Use viewBox properties to zoom
  zoom(scale) {
    let svg = document.getElementById("thumb-container");

    if (scale == 0) {
      this.viewbox.w = 600;
      this.viewbox.h = 400;
    } else {
      this.viewbox.w = this.viewbox.w / scale;
      this.viewbox.h = this.viewbox.h / scale;
    }

    svg.setAttribute(
      "viewBox",
      this.viewbox.x +
        " " +
        this.viewbox.y +
        " " +
        this.viewbox.w +
        " " +
        this.viewbox.h
    );
  }

  wheelZoom($event) {
    if ($event.deltaY > 0) {
      this.zoom(0.9);
    } else if ($event.deltaY < 0) {
      this.zoom(1.1);
    }
  }

  // Use viewBox properties to move the vue
  moveView(direction) {
    let svg = document.getElementById("thumb-container");
    switch (direction) {
      case "up":
        this.viewbox.y = this.viewbox.y - 10;
        break;
      case "down":
        this.viewbox.y = this.viewbox.y + 10;
        break;
      case "left":
        this.viewbox.x = this.viewbox.x - 10;
        break;
      case "right":
        this.viewbox.x = this.viewbox.x + 10;
        break;
    }

    svg.setAttribute(
      "viewBox",
      this.viewbox.x +
        " " +
        this.viewbox.y +
        " " +
        this.viewbox.w +
        " " +
        this.viewbox.h
    );
  }

  disableAllDrag() {
    let elt = this.svgView.getSVGElement().children;
    let arr = Array.from(elt);
    for (let i = 0; i < arr.length; i++) {
      let el = document.querySelector(
        "#" + elt[i].getAttribute("id")
      ) as HTMLElement;
      el.style.pointerEvents = "none";
    }
  }

  activateAllDrag() {
    let elt = this.svgView.getSVGElement().children;
    let arr = Array.from(elt);
    for (let i = 0; i < arr.length; i++) {
      let el = document.querySelector(
        "#" + elt[i].getAttribute("id")
      ) as HTMLElement;

      // We remove the property for all the elements except the cropper
      if (el.getAttribute("id") != "cropper") {
        el.style.removeProperty("pointer-events");
      }
    }
  }

  deleteItem() {
    let modalRef = this.modalService.open(ConfirmationDialogComponent, { centered: true });
    modalRef.componentInstance.content =
      "Please confirm the suppresion of this item ?";
      modalRef.result.then(
      (r) => {
        let item = this.svgView.getSelectedItem().getItem();
        this.thumbnail.removeItem(item);
      },
      (e) => {}
    );
  }

  closeWindow(str) {
    if (this.hasChanged == true) {
      let cf = confirm(str);
      if (cf) {
        this.activeModal.close();
        this.deleteCachePrevState();
      }
    } else {
      this.activeModal.close();
      this.deleteCachePrevState();
    }
  }

  colorChange(color: string) {
    let item = this.svgView.getSelectedItem().getItem();
    if (item instanceof ThumbnailLine) {
      (<ThumbnailLine>item).setColor(color);
    } else if (item instanceof ThumbnailText) {
      (<ThumbnailText>item).setColor(color);
    } else if (item instanceof ThumbnailCurve) {
      (<ThumbnailCurve>item).setColor(color);
    }
  }

  updateThumbnail() {
    this.svgToolkit.urlToBase64(
      `${this.storeUrl}/${this.background.image}`,
      (source) => {
        this.svgToolkit.imageDimensions(source).then((r) => {
          // Get dimension of image
          if (!this.thumbnail.size) {
            this.thumbnail.size = this.svgView.sizeToCrop = Math.min(
              this.svgView.getDimention().width / r.width,
              this.svgView.getDimention().height / r.height
            );
          }

          if (!this.thumbnail.ratio) {
            this.svgView.selectedRatio = 3.5;
            this.thumbnail.ratio = "32/9";
          }

          // Remove previous infrastructure background and assume that we have one background itemFunction: 1 on the thumbnail
          const items = this.thumbnail
            .getItems()
            .filter((item) => item.getFunction() !== 1);
          this.thumbnail.removeAllItems();
          this.thumbnail
            .addItemToFront(
              new ThumbnailImage({ ...r, source, url: this.background.image })
            )
            .setFunction(ThumbnailItemTFunction.BACKGROUND);
          if (items && items.length > 0) {
            items.forEach((item) => {
              this.thumbnail.addItem(item);
            });
          }
          this.createCropper();
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
