import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import jsonpath from "jsonpath";
import { comparePath } from "../utils-shared";

@Component({
  selector: "app-annotation",
  templateUrl: "./annotation.component.html",
  styleUrls: ["./annotation.component.scss"],
})
export class AnnotationComponent implements OnChanges {
  @Input("annotations") annotations: Array<any>;
  @Input("path") path: string;
  @Input("propName") propName: string;
  @Input("readonly") readonly: boolean;
  @Input("simulation") simulation: any;
  @Input() context;
  @Output("annotationChange") annotationChange = new EventEmitter<any>();

  annotation: any;

  isNew = true;

  constructor() {}

  ngOnChanges() {
    if (this.annotations) {
      let annotation = this.annotations.length
        ? this.annotations.find((annotation) => {
            return annotation.path == this.path;
          })
        : null;
      if (annotation) {
        this.annotation = annotation;
        this.isNew = false;
      } else {
        this.annotation = {
          isUnknown: false,
          path: this.path,
          name: "",
          input: false,
          trigger: false,
          expected: false,
        };
      }
      this.annotationChange.emit(this.annotation);
    }
  }

  save() {
    if (this.isNew) {
      try {
        // If the reference object has is a value of a parameter description, get it as the annotation desctiption
        let contextParentObject = this.path.substring(0, this.path.lastIndexOf("."));
        this.annotation.description = jsonpath.query(this.context, contextParentObject)[0]['paramDescription']['description'];
      } catch (error) {}

      this.annotations.push(this.annotation);
      this.isNew = false;
    }

    this.annotationChange.emit(this.annotation);
  }

  setAnnotation(annotation: string) {
    let annotations = ["isUnknown", "mandatory", "optional"];
    annotations.forEach((a) => {
      if (a != annotation) this.annotation[a] = false;
    });
  }

  /**
   * Indicates if the section containing "Mandatory", "Optional" and "Unknown"
   * annotations should be displayed.
   */
  showParameterStatusSection(): boolean {
    return !this.simulation || this.simulation["context"] != "usecase";
  }

  /**
   * Indicated if the section containing "Trigger", "Input", "Expected"
   * and "Parameter name" should be displayed.
   */
  showUseCaseRoleSection(): boolean {
    return !!this.simulation;
  }

  /**
   * Allow annotation edition even in readonly mode.
   * FIXME: temporary implementation to be changed
   */
  overrideReadonly(): boolean {
    return (
      this.showUseCaseRoleSection() &&
      this.simulation["context"] == "usecase" &&
      this.simulation["readonly"] == false
    );
  }
}
