import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Providers
import { AuthGuard } from "providers/authGuard";
import { GroupComponent } from "./shared/group/group.component";
import { SwaggerComponent } from "./shared/swagger/swagger.component";
import { ContainerResolver } from "./resolvers/container.resolver";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    canActivate: [AuthGuard],
    resolve: {
      containerData: ContainerResolver,
    },
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomeModule),
  },

  {
    path: "swagger/:apiRef",
    component: SwaggerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "group/:groupId",
    component: GroupComponent,
    canActivate: [AuthGuard],
  },
  {
    //FIXME: #9602: Patch pour limiter les erreurs dues à l'implem à refactorer, à supprimer
    path: "scenario",
    redirectTo: "scenarios",
    pathMatch: "full"
  },
  {
    path: "scenarios",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./scenario/scenario.module").then((m) => m.ScenarioModule),
  },
  {
    path: "collections",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./collection/collection.module").then((m) => m.CollectionModule),
  },
  //FIXME: #9602: Redirection des collections pour limiter les erreurs dues à l'implem à refactorer, à supprimer
  { path: "infrastructureelement", redirectTo: "/collections/infrastructureelement/" },
  { path: "infrastructure", redirectTo: "/collections/infrastructure/" },
  { path: "behavior", redirectTo: "/collections/behavior/" },
  { path: "actor", redirectTo: "/collections/actor/" },
  { path: "equipment", redirectTo: "/collections/equipment/" },
  { path: "tag", redirectTo: "/collections/tag/" },
  //ENDFIXME
  {
    path: "usecase",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./use-case/use-case.module").then((m) => m.UseCaseModule),
  },
  {
    path: "testprotocol",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./test-protocol/test-protocol.module").then(
        (m) => m.TestProtocolModule
      ),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
