import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor() { }

}
