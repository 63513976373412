<div class="d-flex sections-nav" [ngClass]="{'no-breadcrumb': isHomePage}">
  <div class="action-menu p3" [ngClass]="{'menu-selected': isSelectedMenuScenario }">
    <button class="btn" routerLink="/home" [disabled]="appStatus.isEditionOngoing">
      <mat-icon>view_list</mat-icon>
      <span> Scenarios </span>
    </button>
  </div> 
  <div class="action-menu p3" [ngClass]="{'menu-selected': isSelectedMenuUseCase}">
    <button class="btn" routerLink="/home/usecase" [disabled]="appStatus.isEditionOngoing">
      <mat-icon>commute</mat-icon>
      <span> Systems & Use-cases</span>
    </button>
  </div>
  <div class="action-menu p3" [ngClass]="{'menu-selected': isSelectedMenuTestProtocol}"> 
    <button class="btn" routerLink="/home/testprotocol" [disabled]="appStatus.isEditionOngoing">
      <mat-icon>fact_check</mat-icon>
      <span> Test Protocols </span>
    </button>
  </div>
  <div class="action-menu p3" [ngClass]="{'menu-selected': isSelectedMenuCollection}">
    <button class="btn" routerLink="/collections" [disabled]="appStatus.isEditionOngoing">
      <mat-icon>style</mat-icon>
      <span> Frames of reference </span>
    </button>
  </div>
</div>

<nav>
  <ol class="breadcrumb" *ngIf="!isHomePage">
    <li class="breadcrumb-item">
      <mat-icon class="mx-2">home</mat-icon>
    </li>
    <li class="breadcrumb-item text-capitalize" *ngFor="let pathItem of pathItems; let i = index">
        <a [routerLink]="pathItem.route" routerLinkActive="active" [class.disabled]="appStatus.isEditionOngoing">
          {{ pathItemsLabels[i] }}
        </a>
    </li>
  </ol>
</nav>