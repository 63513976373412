import { NgModule } from "@angular/core";
import {
  NgbModalModule,
  NgbDropdownModule,
  NgbCarouselModule,
  NgbTooltipModule,
  NgbPopoverModule,
  NgbPopoverConfig,
  NgbDropdownConfig,
  NgbButtonsModule,
  NgbAlertModule,
} from "@ng-bootstrap/ng-bootstrap";

const ngbModules = [
  NgbModalModule,
  NgbDropdownModule,
  NgbCarouselModule,
  NgbTooltipModule,
  NgbPopoverModule,
  NgbButtonsModule,
  NgbAlertModule,
];

@NgModule({
  imports: ngbModules,
  exports: ngbModules,
})
export class NgbModule {
  constructor(
    popoverConfig: NgbPopoverConfig,
    dropdownConfig: NgbDropdownConfig
  ) {
    popoverConfig.autoClose = "outside";
    dropdownConfig.container = "body";
  }
}
