import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigureService } from "service/ng4-configure/ng4-configure.service";
import { BehaviorSubject, forkJoin, from, Observable, of } from "rxjs";
import { ContainerService } from "./container.service";
import { CollectionService } from "app/collection/collection.service";
import { mergeMap, mergeAll, map, toArray, catchError, share } from "rxjs/operators";
import { ScenarioService } from "app/scenario/scenario.service";
import { LibraryService } from "./library-service";
import { FilterContainer } from "model/collection";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  //FIXME: A refactorer complètement, les fonctions devraient être dispatchées dans des services ad'hoc,
  // les propriétés devraient a priori juste ne pas être mutualisés

  filterFormSub = new BehaviorSubject<FilterContainer[] | string>([]);
  filterForm$: Observable<FilterContainer[] | string> = this.filterFormSub.asObservable();

  // Get container library like use case, test protocol
  containerLibrary$ = (nameApi: string, containerId: Number) =>
    this.containerService.getContainerById(nameApi, containerId).pipe(
      map((container) => ({ containerId, container: container.resources[0] })),
      catchError((err) => of({ containerId, container: null }))
    );

  scenario$ = (scenarioId: Number) =>
    this.scenarioService.getScenarioById(scenarioId).pipe(
      map(({ idContainer, permissions, resources }: any) => ({
        idContainer,
        permissions,
        value: resources[0],
      })),
      catchError((err) => of({ scenarioId, idContainer:null, permissions: null, value: null }))
      //catchError((err) => of([]))
    );

  stepsWithTriggers$ = (scenario) =>
    this.scenarioService.getScenarioAnnotations(scenario.id).pipe(
      map(({ annotations, id }) => {
        if (!scenario?.steps || !scenario.steps.length) {
          return [];
        }
        const triggersTab = annotations.filter(
          (annotation) => annotation.trigger
        );
        if (!annotations || !annotations?.length || !triggersTab.length) {
          return scenario.steps.map((step) => {
            return { ...step, triggers: [] };
          });
        }

        return scenario.steps.map((step) => {
          const triggers = triggersTab.filter((trigger) =>
            trigger.path.includes(step.id)
          );
          return { ...step, triggers };
        });
      }),
      share()
    );

  constructor(
    private http: HttpClient,
    private configService: ConfigureService,
    private containerService: ContainerService,
    private collectionService: CollectionService,
    private scenarioService: ScenarioService,
    private libraryService: LibraryService
  ) {}

  identifiersExists(identifiers: Array<string>) {
    return this.http.get<Array<any>>(
      `${this.configService.config.apiUrl}/util/identifiers`,
      { params: { identifiers } }
    );
  }

  getCollection(collection: string) {
    return this.containerService.getContainers(collection).pipe(
      mergeMap((containers) =>
        from(containers).pipe(
          mergeMap((container) =>
            this.collectionService
              .findCollections(collection, container.resources[0].id)
              .pipe(map((collections) => collections.resources))
          ),
          mergeAll(),
          toArray()
        )
      )
    );
  }

  getCollectionByContainer(containers: Number[], collection: string) {
    if (containers.length === 0) {
      return of(null);
    }
    return from(containers).pipe(
      mergeMap((containerId) => {
        return forkJoin([
          this.containerService.getContainerById(collection, containerId, true),
          this.collectionService.findCollections(collection, containerId, true),
        ]).pipe(
          map(([container, collections]) => ({
            container: container.resources[0],
            disabled: !container.permissions.CAN_CREATE_COLLECTION,
            [`${collection}s`]: collections.resources,
            collection,
          })),
          catchError((err) =>
            of({ container: null, disabled: null, [`${collection}s`]: [], collection })
          )
        );
      })
    );
  }

  getCollections(collection: string, containerId: Number) {
    if (!containerId) {
      return of([]);
    }
    return this.collectionService
      .findCollections(collection, containerId)
      .pipe(map((resp) => resp.resources));
  }

  getScenarios(containerId) {
    if (!containerId) {
      return of([]);
    }
    return this.scenarioService.findScenarios(null, containerId, []).pipe(
      map((resp) => resp.resources || []),
      catchError((err) => of([]))
    );
  }

  getLibraries(containerId, nameApi: string) {
    if (!containerId) {
      return of([]);
    }
    const parameters = {
      container: containerId,
      filters: [],
    };
    return this.libraryService
      .findLibrary(parameters, nameApi)
      .pipe(map((resp) => resp.resources));
  }

  getContainers(collection: string) {
    return this.containerService.getContainers(collection).pipe(
      map((resp) =>
        resp.map((c) => {
          return {
            ...c.resources[0],
            disabled: !c.permissions.CAN_CREATE_COLLECTION,
          };
        })
      )
    );
  }

  getPermissionStatus(permissions: any) {
    switch(permissions){
      case 'DRAFT':
        return true;
      case 'READY':
        return permissions && permissions.CAN_REOPEN_READY_SCENARIO;
      case 'FEEDBACK':
        return permissions && permissions.CAN_REOPEN_FEEDBACK_SCENARIO;
      case 'VALIDATED':
        return permissions && permissions.CAN_REOPEN_VALIDATED_SCENARIO;;
    }
  }
}
